<div class="status-message-dialogue" *ngIf="isVisible">
  <div class="status-techn-wrapper">
    <div class="status-message" [innerHTML]="messageText"></div>
  </div>
  <div class="frame-parent">
    <div class="ok-wrapper">
      <div class="ok">OK</div>
    </div>
    <img class="iconclose" (click)="closeLink()" alt="" src="assets/img/toastclose.svg">
  </div>
  <div class="status-dialog" [ngClass]="messageType"></div>
</div>
    