import { Component, input, output } from '@angular/core';
import { MappedTedDomain } from '../ted-domains/ted-domains.component';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.css',
})
export class PopupComponent {
  constructor(public gaService: GoogleAnalyticsService) {}
  public activeDomain = input.required<MappedTedDomain>();
  public isTooltip = input<boolean>(false);

  public closePopup = output<void>();

  public onClosePopup(): void {
    this.closePopup.emit();
  }
}
