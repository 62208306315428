
<div class="row column resultCard" [ngClass]="{'noBorderCard': resultRecIndex === 0}">
  <div style="display: flex;" [ngStyle]="{'margin-left': isSequenceSearch ? '0px' : '-50px'}"> 

    <div class="vf-form__item vf-form__item--checkbox"  *ngIf="!isSequenceSearch">
      <input
        type="checkbox"
        name="{{ resultRec.uniprotAccession }}"
        id="{{ resultRec.uniprotAccession }}"
        value="{{ resultRec.uniprotAccession }}"
        class="vf-form__checkbox"
        [checked]="isSelected"
        [disabled]="isLimitReached && !isSelected"
        (change)="notifySelectionChange($event)">
      <label for="{{ resultRec.uniprotAccession }}" class="vf-form__label"></label>
    </div>
  
    <div>
      <a class="vf-link" style="display: inline-block; box-shadow: none;"
      [routerLink]="['/entry', resultRec.uniprotAccession]" (click)="gaService.eventEmitter(
        'search_click_through',
        'search',
        'click',
        resultRec.uniprotAccession,
        undefined
        )">
        <h3 style="margin-bottom: 4px;" class="resultTitle">{{resultRec.uniprotDescription}}</h3>

      </a>
      <app-entry-status [isProteome]="(resultRec.isReferenceProteome)" [isReviewed]="(resultRec.isReviewed)"></app-entry-status>
    </div>
  </div>

  <div class="resultSubtitle">
    {{resultRec.entryId}}-v{{resultRec.latestVersion}}
    <!-- {{resultRec.uniprotAccession}} ({{resultRec.uniprotId}}) -->
  </div>

<!--  <div class="row resultRow">-->
<!--    <div class="resultLeftCol columns small-4 medium-2">Status</div>-->
<!--    <div class="resultRightCol columns small-8 medium-10">-->
<!--      <app-entry-status [isProteome]="(resultRec.isReferenceProteome)" [isReviewed]="(resultRec.isReviewed)"></app-entry-status>-->
<!--    </div>-->
<!--  </div>-->
  <div class="row resultRow">
    <div class="resultLeftCol columns small-4 medium-2">Protein</div>
    <div class="resultRightCol columns small-8 medium-10">{{resultRec.uniprotDescription}}</div>
  </div>
  <div class="row resultRow">
    <div class="resultLeftCol columns small-4 medium-2">Gene</div>
    <div class="resultRightCol columns small-8 medium-10">{{resultRec.gene ? resultRec.gene : 'Not available'}}</div>
  </div>
  <div class="row resultRow">
    <div class="resultLeftCol columns small-4 medium-2">Source Organism</div>
    <div class="resultRightCol columns small-8 medium-10">
      {{resultRec.organismScientificName}}
      <a class="vf-link ext" target="_blank"
         href="./search/organismScientificName/{{commonService.escapeValue(resultRec.organismScientificName)}}">search this organism
      </a>
    </div>
  </div>
  <div class="row resultRow">
    <div class="resultLeftCol columns small-4 medium-2">UniProt</div>
    <div class="resultRightCol columns small-8 medium-10">{{resultRec.uniprotAccession}}<a target="_blank"
                                                                                           href="http://www.uniprot.org/uniprot/{{resultRec.uniprotAccession}}"
                                                                                           class="vf-link ext">go to
      UniProt</a></div>
  </div>
  <div class="row resultRow" *ngIf="kbCount && kbCount > 0">
    <div class="resultLeftCol columns small-4 medium-2">Experimental structures</div>
    <div class="resultRightCol columns small-8 medium-10">{{kbCount}} PDB structure{{kbCount > 1 ? 's' : ''}}
      for {{resultRec.uniprotAccession}}<a target="_blank"
                                           href="https://www.ebi.ac.uk/pdbe/pdbe-kb/proteins/{{resultRec.uniprotAccession}}"
                                           class="vf-link ext">go to PDBe-KB</a></div>
  </div>
  <div class="row resultRow">
    <div class="resultLeftCol columns small-4 medium-2">Average pLDDT</div>
    <div class="resultRightCol columns small-8 medium-10">
      {{resultRec.globalMetricValue}} ({{ getPlddtLevel(resultRec.globalMetricValue) }})
    </div>
  </div>
  <div class="row resultRow">
    <div class="resultLeftCol columns small-4 medium-2">Sequence length</div>
    <div class="resultRightCol columns small-8 medium-10">
      {{ (resultRec.uniprotEnd - resultRec.uniprotStart) + 1 }}
    </div>
  </div>

    <app-sequence-card *ngIf="isSequenceSearch" [hsps]="resultRec.sequenceData"></app-sequence-card>

 
</div>
