<div class="columns viewTitleBox">
  <div
    class="columns mobileModal-view dropdown"
    role="button"
    (click)="toggleDropdown()"
    *ngIf="csvAmData || tedDomainData()?.length"
  >
    <span>{{ dropdownOptionSelected() }}</span>
    <i
      class="icon icon-common icon-angle-{{ dropdownOpen() ? 'up' : 'down' }}"
    ></i>
  </div>
  <section class="columns dropdown-items" *ngIf="dropdownOpen()">
    <div
      class="dropdown-item"
      (click)="applyMolstarColoring('plddt'); updateDropdownView()"
      [ngStyle]="{'background-color': dropdownOptionSelected() === 'Model Confidence' ? '#D1E3F6' : 'white'}"
    >
      Model Confidence
    </div>
    <div
      *ngIf="csvAmData"
      class="dropdown-item"
      (click)="applyMolstarColoring('missense'); updateDropdownView()"
      [ngStyle]="{'background-color': dropdownOptionSelected() === 'AlphaMissense Pathogenicity' ? '#D1E3F6' : 'white'}"
    >
      AlphaMissense Pathogenicity
    </div>
    <div
      *ngIf="tedDomainData()?.length"
      class="dropdown-item"
      (click)="applyMolstarColoring('ted'); updateDropdownView()"
      [ngStyle]="{'background-color': dropdownOptionSelected() === 'TED Domains' ? '#D1E3F6' : 'white'}"
    >
      TED Domains
    </div>
  </section>
  <div
    class="columns modalspace mobileModal-view"
    *ngIf="isModalConf == true && isTedDomain() === false && isShowModelLegends"
  >
    <span
      class="titleModelConf"
      *ngIf="!csvAmData && tedDomainData()?.length === 0"
      >Model Confidence
      <div class="label-tooltip" style="display: inline; margin-left: 8px">
        <svg
          style="width: 15px; height: 15px"
          viewBox="0 0 24 24"
          (mouseover)="
            gaService.eventEmitter(
              'molstar-tooltip',
              'tooltip',
              'mouseover',
              accession,
              undefined
            )
          "
        >
          <path
            fill="#3B6FB6"
            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
          />
        </svg>
        <span
          class="label-tooltip-text modelconfPara"
          style="
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            white-space: break-spaces;
          "
        >
          pLDDT corresponds to the model’s prediction of its score on the local
          Distance Difference Test (<a
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3799472/"
            target="_blank"
            class="licenseLink"
            >lDDT-Cα</a
          >). It is a measure of local accuracy - for interpreting larger scale
          features like relative domain positions see the “predicted aligned
          error” plot and corresponding tutorial. Confidence bands are used to
          colour-code the residues in the 3D viewer. The exact pLDDT value is
          shown when you mouseover the structure or the sequence. It can also be
          found in the B-factor fields of the downloadable coordinate files.
        </span>
      </div>
    </span>

    <div class="modelInfo">
      <div class="confidenceWrapper">
        <div class="column legendRow" *ngFor="let legend of viewerLegend">
          <span class="legendColor" [ngStyle]="legend.style">&nbsp;</span
          ><span class="legendlabel">{{ legend.label }}</span>
        </div>
      </div>
      <div class="column legendDesc">
        AlphaFold produces a per-residue model confidence score (pLDDT) between
        0 and 100. Some regions below 50 pLDDT may be unstructured in isolation.
      </div>
    </div>
  </div>

  <div
    class="columns modalspace mobileModal-view"
    *ngIf="isAlphaMis == true && isShowModelLegends && csvAmData"
  >
    <div class="modelInfo">
      <div class="confidenceWrapperAM">
        <div
          class="column legendRow"
          *ngFor="let alpha of alphamissenseLegends"
        >
          <span class="legendColor" [ngStyle]="alpha.style">&nbsp;</span
          ><span class="legendlabel">{{ alpha.label }}</span>
        </div>
      </div>
      <div class="column legendDesc">
        The displayed colour for each residue is the average AlphaMissense
        pathogenicity score across all possible amino acid <br/>substitutions at that
        position.
      </div>
    </div>
  </div>

  <div
    class="columns modalspace mobileModal-view"
    *ngIf="isTedDomain() && tedDomainData().length"
  >
    <div class="modelInfo">
      <div
        class="confidenceWrapperAM tedWrapper"
        [style.width]="wrapperWidth()"
      >
        <div
          class="column legendRow tedWrapper-item"
          *ngFor="let ted of slicedTedDomain(); index as i"
        >
          <span
            class="legendColor"
            [style.background]="utilService.fetchColorByIndex(i)"
            >&nbsp;</span
          ><span class="legendlabel"
            >Domain {{ ted.ted_domain_no }}</span
          >
        </div>
      </div>
      <div class="column legendDesc">
        <span
          class="show-all-teds"
          role="button"
          (click)="onShowMoreLegends()"
          *ngIf="isTedDomainMoreThanThree()"
        >
          {{ legendText() }}
          <i class="icon icon-common" data-icon="&#xf078;"></i>
        </span>
      </div>
    </div>
  </div>
  <div class="columns small-12 medium-9 molstarView">
    <div
      class="small-12 columns molstar-container"
      (click)="
        gaService.eventEmitter(
          'molstar-click',
          'molstar',
          'click',
          accession,
          undefined
        )
      "
    ></div>
  </div>
  <div
    class="columns modalspace bigModal-view"
    *ngIf="csvAmData || tedDomainData()?.length"
  >
    <mat-button-toggle-group
      class="tab"
      name="molstar-coloring"
      aria-label="Molstar Color"
      [(ngModel)]="selectedTab"
    >
      <mat-button-toggle
        value="plddt"
        class="titleModel"
        checked
        (change)="applyMolstarColoring($event.value)"
        >Model Confidence

        <div class="label-tooltip" style="display: inline; margin-left: 8px">
          <svg
            style="width: 15px; height: 15px"
            viewBox="0 0 24 24"
            (mouseover)="
              gaService.eventEmitter(
                'molstar-tooltip',
                'tooltip',
                'mouseover',
                accession,
                undefined
              )
            "
          >
            <path
              fill="#1A1C1A"
              d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
            />
          </svg>
          <span
            class="label-tooltip-text modelconfPara"
            style="
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              white-space: break-spaces;
            "
          >
            pLDDT corresponds to the model’s prediction of its score on the
            local Distance Difference Test (<a
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3799472/"
              target="_blank"
              class="licenseLink"
              >lDDT-Cα</a
            >). It is a measure of local accuracy - for interpreting larger
            scale features like relative domain positions see the “predicted
            aligned error” plot and corresponding tutorial.

            <p class="modelconfPara">
              Confidence bands are used to colour-code the residues in the 3D
              viewer. The exact pLDDT value is shown when you mouseover the
              structure or the sequence. It can also be found in the B-factor
              fields of the downloadable coordinate files.
            </p>
          </span>
        </div>
      </mat-button-toggle>

      <mat-button-toggle
        *ngIf="csvAmData"
        value="missense"
        class="titleModel"
        (change)="applyMolstarColoring($event.value)"
        >AlphaMissense Pathogenicity

        <div class="label-tooltip" style="display: inline; margin-left: 8px">
          <svg
            style="width: 15px; height: 15px"
            viewBox="0 0 24 24"
            (mouseover)="
              gaService.eventEmitter(
                'alphmissense-tooltip',
                'tooltip',
                'mouseover',
                accession,
                ''
              )
            "
          >
            <path
              fill="#1A1C1A"
              d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
            />
          </svg>
          <span
            class="label-tooltip-text modelconfPara"
            style="
              height: fit-content;
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              white-space: break-spaces;
              width: 450px
            "
          >
            The pathogenicity score classifies variants into 3 categories:
            Likely benign, Uncertain and Likely pathogenic. The cutoffs were
            chosen such that variants classified as likely pathogenic or likely
            benign have 90% expected precision estimated from ClinVar for both
            classes (<a
              class="licenseLink"
              href="https://doi.org/10.1126/science.adg7492"
              target="_blank"
              >Cheng J. et al., 2023</a
            >).
          </span>
        </div>
      </mat-button-toggle>

      <mat-button-toggle
        *ngIf="tedDomainData()?.length"
        value="ted"
        class="titleModel"
        (change)="applyMolstarColoring($event.value)"
        >TED Domains
        <div class="label-tooltip" style="display: inline; margin-left: 8px">
          <svg
            style="width: 15px; height: 15px"
            viewBox="0 0 24 24"
            (mouseover)="
              gaService.eventEmitter(
                'molstar-tooltip',
                'tooltip',
                'mouseover',
                accession,
                undefined
              )
            "
          >
            <path
              fill="#1A1C1A"
              d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
            />
          </svg>
          <span
            class="label-tooltip-text modelconfPara ted-tooltip"
            style="
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              white-space: break-spaces;
            "
          >
            <p>
              The Encyclopedia of Domains (TED), segments and classifies
              structural domains in AlphaFold DB, using consensus agreements
              across multiple structure-based methods for confident boundaries
              <a
                href="https://www.science.org/doi/10.1126/science.adq4946"
                target="_blank"
                class="licenseLink"
                >(Lau et al., 2024)</a
              >.
            </p>
          </span>
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div
    class="columns modalspace bigModal-view"
    *ngIf="isModalConf == true && isTedDomain() === false && isShowModelLegends"
  >
    <span
      class="titleModelConf"
      *ngIf="!csvAmData && tedDomainData()?.length === 0"
      >Model Confidence
      <div class="label-tooltip" style="display: inline; margin-left: 8px">
        <svg
          style="width: 15px; height: 15px"
          viewBox="0 0 24 24"
          (mouseover)="
            gaService.eventEmitter(
              'molstar-tooltip',
              'tooltip',
              'mouseover',
              accession,
              undefined
            )
          "
        >
          <path
            fill="#3B6FB6"
            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
          />
        </svg>
        <span
          class="label-tooltip-text modelconfPara"
          style="
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            white-space: break-spaces;
          "
        >
          pLDDT corresponds to the model’s prediction of its score on the local
          Distance Difference Test (<a
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3799472/"
            target="_blank"
            class="licenseLink"
            >lDDT-Cα</a
          >). It is a measure of local accuracy - for interpreting larger scale
          features like relative domain positions see the “predicted aligned
          error” plot and corresponding tutorial. Confidence bands are used to
          colour-code the residues in the 3D viewer. The exact pLDDT value is
          shown when you mouseover the structure or the sequence. It can also be
          found in the B-factor fields of the downloadable coordinate files.
        </span>
      </div>
    </span>

    <div class="modelInfo">
      <div class="confidenceWrapper">
        <div class="column legendRow" *ngFor="let legend of viewerLegend">
          <span class="legendColor" [ngStyle]="legend.style">&nbsp;</span
          ><span class="legendlabel">{{ legend.label }}</span>
        </div>
      </div>
      <div class="column legendDesc">
        AlphaFold produces a per-residue model confidence score (pLDDT) between
        0 and 100. Some regions below 50 pLDDT may be unstructured in isolation.
      </div>
    </div>
  </div>

  <div
    class="columns modalspace bigModal-view"
    *ngIf="isAlphaMis == true && isShowModelLegends && csvAmData"
  >
    <div class="modelInfo">
      <div class="confidenceWrapperAM">
        <div
          class="column legendRow"
          *ngFor="let alpha of alphamissenseLegends"
        >
          <span class="legendColor" [ngStyle]="alpha.style">&nbsp;</span
          ><span class="legendlabel">{{ alpha.label }}</span>
        </div>
      </div>
      <div class="column legendDesc">
        The displayed colour for each residue is the average AlphaMissense
        pathogenicity score across all possible amino acid <br/>substitutions at that
        position.
      </div>
    </div>
  </div>

  <div
    class="columns modalspace bigModal-view"
    *ngIf="isTedDomain() && tedDomainData().length"
  >
    <div class="modelInfo">
      <div
        class="confidenceWrapperAM tedWrapper"
        [style.width]="wrapperWidth()"
      >
        <div
         
          class="column legendRow tedWrapper-item"
          *ngFor="let ted of slicedTedDomain(); index as i"
        >
          <span
            class="legendColor"
            [style.background]="utilService.fetchColorByIndex(i)"
            >&nbsp;</span
          ><span class="legendlabel"
            >Domain {{ ted.ted_domain_no }}</span
          >
        </div>
      </div>
      <div class="column legendDesc">
        <span
          class="show-all-teds"
          role="button"
          (click)="onShowMoreLegends()"
          *ngIf="isTedDomainMoreThanThree()"
        >
          {{ legendText() }}
          <i class="icon icon-common" data-icon="&#xf078;"></i>
        </span>
      </div>
    </div>
  </div>
</div>
