import { Component, EventEmitter, Input, OnInit, Output, output } from '@angular/core';

@Component({
  selector: 'app-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.css']
})
export class StatusMessageComponent implements OnInit {
  @Input() messageText: string = 'Operation completed successfully';
  @Input() messageType: 'error' | 'success' | 'warning' = 'success';
  @Output() closeLinkClick:EventEmitter<string> = new EventEmitter();

  isVisible: boolean = true;

  constructor() {}

  ngOnInit(): void {
    // Automatically dismiss message after 5 seconds
    // setTimeout(() => {
    //   this.dismissMessage();
    // }, 5000);
  }

  dismissMessage(): void {
    this.isVisible = false;
  }
  closeLink() {
    // e.preventDefault();
    this.closeLinkClick.emit();
  }
}