<ng-container *ngIf="tedDomainData()?.length; else empty">
  <div
    class="outter"
    [style.padding-left]="paddingLeft()"
    [style.padding-right]="paddingRight()"
  >
    <div class="line-through"></div>
    <div
      class="domain"
      *ngFor="let d of mappedTedDomainData(); let i = index"
      [class.hover-active]="d.tedNumber === tedDomainNumber()"
      [class.click-active]="d.tedNumber === tedDomainNumberForClickActive()"
      [style.background]="d.color"
      [style.width]="d.width + 'px'"
      [style.margin-right]="d.gap + 'px'"
      (mouseenter)="onHover(d, rectElement)"
      (mouseleave)="onLeave()"
      (click)="onClick(d, i, rectElement)"
      #rectElement
    ></div>
    <ng-container *ngIf="popupVisible()">
      <app-popup
        [activeDomain]="activeDomain()"
        (closePopup)="closePopup()"
      ></app-popup>
    </ng-container>
    <ng-container *ngIf="popupTooltipVisible()">
      <app-popup
        [activeDomain]="activeTooltipDomain()"
        [isTooltip]="true"
      ></app-popup>
    </ng-container>
  </div>
</ng-container>
<ng-template #empty>
  <p class="no-domain">No domain information for this entry</p>
</ng-template>
