<div class="row threedViewer" style="margin-top:20px;">
  <div class="filledBox">
    <div class="title">
      AlphaMissense Pathogenicity Heatmap
      <div class="list-file dropdown-files">
        <app-file-download
          [isWide]="true"
          [csvAMFileUrl]="csvAMFileUrl"
          [amAnnotationsHg19Url]="amAnnotationsHg19Url"
          [amAnnotationsHg38Url]="amAnnotationsHg38Url"
          [dropdownOptions]="dropdownOptions"
          (selected)="handleSelection($event)"
        >
        </app-file-download>
      </div>
      <div class="list-file">
        <a
          class="learnlink"
          [routerLink]="['/faq']"
          fragment="faq-22"
          target="_blank"
          (click)="
            gaService.eventEmitter(
              'AM_link_visit',
              'AlphaMissense',
              'click',
              'AM_learn_more',
              'Clicks on the alphamissense learn more link'
            )
          "
        >
          Learn more about AlphaMissense
        </a>
      </div>
    </div>
  </div>
</div>

<div class="row ForMobile">
  <div class="columns">
    <div class="disclaimerBox">
      <p class="disclaimerText">
        The heatmap is unavailable for mobile devices. Please use a larger
        screen to see the heatmap.
      </p>
    </div>
  </div>
</div>

<div class="row forBigDesktop">
  <div class="nightingale-container">
    <nightingale-manager>
      <div *ngIf="heatmapSequence" class="protvista-components">
        <nightingale-navigation
          width="717"
          height="36"
          [length]="heatmapSequence.length"
        >
        </nightingale-navigation>
        <nightingale-sequence
          [sequence]="heatmapSequence"
          width="717"
          height="18"
          [length]="heatmapSequence.length"
        >
        </nightingale-sequence>
      </div>
    </nightingale-manager>
  </div>

  <div class="am-container">
    <div class="heatmap-container">
      <div id="heatmap-test"></div>
    </div>

    <div class="filter-container">
      <div class="range-filter">
        <div class="legend-container">
          <div class="range-names">
            <span>Benign</span>
            <span>Uncertain</span>
            <span>Pathogenic</span>
            <!-- <span class="wild-type-title">Reference </span> -->
            <span class="wild-type-title">
              Reference
              <div class="label-tooltip" style="display: inline">
                <svg
                  style="width: 18px; height: 18px; margin-bottom: -4px"
                  viewBox="0 0 24 24"
                  (mouseover)="
                    gaService.eventEmitter(
                      'molstar-tooltip',
                      'tooltip',
                      'mouseover',
                      accession,
                      undefined
                    )
                  "
                >
                  <path
                    fill="#373A36"
                    d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
                  />
                </svg>
                <span
                  class="label-tooltip-text"
                  style="font-size: 14px; font-weight: 400; text-align: left"
                >
                  Amino acid present in the reference UniProt protein sequence,
                  based on HG38
                </span>
              </div>
            </span>
          </div>

          <div class="legend-images">
            <div
              style="
                width: 318px;
                height: 12px;
                background: linear-gradient(
                  90deg,
                  #2166ac 0%,
                  #4290bf 11.32%,
                  #8cbcd4 22.64%,
                  #c3d6e0 33.95%,
                  #e2e2e2 45.27%,
                  #edcdba 58.95%,
                  #e99e7c 72.64%,
                  #d15e4b 86.32%,
                  #b2182b 100%
                );
              "
            ></div>
            <div class="wild-type"></div>
          </div>

          <ul class="pae-scale-ticks-h">
            <li>0.0</li>
            <li>0.2</li>
            <li>0.3</li>
            <li>0.4</li>
            <li>0.5</li>
            <li>0.6</li>
            <li>0.7</li>
            <li>0.8</li>
            <li>1.0</li>
            <li>-</li>
          </ul>
        </div>
      </div>
      <div class="category-filter">
        <div class="title-reset-container">
          <div class="filter-title">Filter by category</div>
          <a class="resetLink" (click)="resetFilters()" *ngIf="isReset"
            >Reset filters</a
          >
        </div>

        <div class="vf-form__item vf-form__item--checkbox benignBox">
          <div class="catogeryBox">
            <input
              #benign
              type="checkbox"
              id="benign"
              value="benign"
              class="vf-form__checkbox"
              [checked]="isBenign"
              (change)="
                (!isBenign);
                isBenignDisabled = !isBenignDisabled;
                handleFilters('category', 'benign')
              "
              [(ngModel)]="isBenign"
            />
            <label for="benign" class="vf-form__label">Likely benign</label>
          </div>
          <div class="sliderBox">
            <mat-slider
              #mySlider1
              min="0"
              max="0.34"
              step="0.01"
              [discrete]="true"
              [disabled]="isBenignDisabled"
              (change)="handleFilters('range', 'benign')"
            >
              <input
                matSliderStartThumb
                [(ngModel)]="silder1Min"
                #slider1_min
              />
              <input matSliderEndThumb [(ngModel)]="silder1Max" #slider1_max />
            </mat-slider>
            <div
              class="benign-range-values"
              [ngClass]="isBenignDisabled ? 'disabled-num' : ''"
            >
              <span>0.0</span>

              <span>0.34</span>
            </div>
          </div>
        </div>

        <div class="vf-form__item vf-form__item--checkbox uncertainBox">
          <div class="catogeryBox">
            <input
              #uncertain
              type="checkbox"
              id="uncertain"
              value="ambiguous"
              class="vf-form__checkbox"
              [checked]="isUncertain"
              (change)="
                (!isUncertain);
                isUncertainDisabled = !isUncertainDisabled;
                handleFilters('category', 'uncertain')
              "
              [(ngModel)]="isUncertain"
            />
            <label for="uncertain" class="vf-form__label">Uncertain</label>
          </div>
          <div class="sliderBox">
            <mat-slider
              #mySlider2
              min="0.34"
              max="0.56"
              step="0.01"
              [disabled]="isUncertainDisabled"
              [discrete]="true"
              (change)="handleFilters('range', 'uncertain')"
            >
              <input
                matSliderStartThumb
                [(ngModel)]="silder2Min"
                #slider2_min
              />
              <input matSliderEndThumb [(ngModel)]="silder2Max" #slider2_max />
            </mat-slider>
            <div
              class="uncertain-range-values"
              [ngClass]="isUncertainDisabled ? 'disabled-num' : ''"
            >
              <span>0.34</span>

              <span>0.564</span>
            </div>
          </div>
        </div>

        <div class="vf-form__item vf-form__item--checkbox pathogenicBox">
          <div class="catogeryBox">
            <input
              #pathogenic
              type="checkbox"
              id="pathogenic"
              value="pathogenic"
              class="vf-form__checkbox"
              [checked]="isPathogenic"
              (change)="
                (!isPathogenic);
                isPathogenicDisabled = !isPathogenicDisabled;
                handleFilters('category', 'pathogenic')
              "
              [(ngModel)]="isPathogenic"
            />
            <label for="pathogenic" class="vf-form__label"
              >Likely pathogenic</label
            >
          </div>
          <div class="sliderBox">
            <mat-slider
              #mySlider3
              min="0.56"
              max="1"
              step="0.01"
              [disabled]="isPathogenicDisabled"
              [discrete]="true"
              (change)="handleFilters('range', 'pathogenic')"
            >
              <input
                matSliderStartThumb
                [(ngModel)]="silder3Min"
                #slider3_min
              />
              <input matSliderEndThumb [(ngModel)]="silder3Max" #slider3_max />
            </mat-slider>
            <div
              class="pathogenic-range-values"
              [ngClass]="isPathogenicDisabled ? 'disabled-num' : ''"
            >
              <span>0.564</span>

              <span>1</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
