<div
  class="vf-dropdown"
  #vfDropdown
  [ngClass]="isWide == true ? 'dropdown-wide' : 'dropdown-simple'"
  data-vf-js-dropdown
>
  <div class="vf-dropdown__label-container">
    <div
      class="vf-dropdown__label"
      [ngClass]="isWide == true ? 'label-wide' : 'label-simple'"
      style="text-align: left"
    >
      Download data
    </div>
    <div [ngClass]="isSvgTop == true ? 'label-simple-pos' : ''">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M9.00003 12.3075L3.96753 7.28253L5.03253 6.21753L9.00003 10.1925L12.9675 6.21753L14.0325 7.28253L9.00003 12.3075Z"
          fill="#3B6FB6"
        />
      </svg>
    </div>
  </div>

  <div
    class="vf-dropdown__menu-container"
    [ngClass]="isWide == true ? 'container-wide' : 'container-simple'"
  >
    <div class="vf-dropdown-overlay"></div>
    <div class="vf-dropdown__menu">
      <div *ngFor="let data of dropdownOptions" class="vf-dropdown__menu-item">
        <!-- Dropdown option label -->
        <!-- <a *ngIf="data.isShow" class="vf-dropdown__menu-item-link" [ngClass]="isWide == true ? 'item-wide' : 'item-simple'" (click)="select(data.value)">
          {{ data.label }}
        </a> -->

        <!-- <a
          *ngIf="data.isShow"
          class="vf-dropdown__menu-item-link"
          [ngClass]="{'item-disabled': data.disabled, 'item-wide': isWide, 'item-simple': !isWide}"
          [ngStyle]="data.disabled ? {'color': 'gray', 'cursor': 'not-allowed'} : {}"
          (click)="!data.disabled && select(data.value)"
          [title]="data.disabled ? 'Please select entries to download' : ''">
          {{ data.label }}
        </a> -->

        <!-- [ngClass]="{'item-disabled': data.disabled, 'item-wide': isWide, 'item-simple': !isWide}"
         [ngStyle]="data.disabled ? {'color': 'gray', 'cursor': 'not-allowed', 'position': 'relative'} : {}"
         (mouseenter)="data.disabled && showTooltip = true"
          (mouseleave)="showTooltip = false
          (click)="!data.disabled && select(data.value)"-->
        <a
          *ngIf="data.isShow"
          class="vf-dropdown__menu-item-link"
          [ngClass]="{
            'item-disabled': data.disabled,
            'item-wide': isWide,
            'item-simple': !isWide
          }"
          [ngStyle]="
            data.disabled
              ? { color: 'gray', cursor: 'not-allowed', position: 'relative' }
              : {}
          "
          (mouseenter)="data.disabled && (showTooltip = true)"
          (mouseleave)="showTooltip = false"
          (click)="!data.disabled && select(data.value)"
        >
          {{ data.label }}

          <!-- Custom tooltip -->
          <ng-container *ngIf="data.disabled && showTooltip">
            <div class="custom-tooltip">
              {{ data.disabled ? "Please select entries to download" : "" }}
            </div>
          </ng-container>
        </a>

        <!-- Optional info text below option label -->
        <div
          *ngIf="data.info"
          class="vf-dropdown__menu-item-info"
          [ngClass]="{
            'item-disabled': data.disabled,
            'item-wide': isWide,
            'item-simple': !isWide
          }"
          [ngStyle]="
            data.disabled
              ? { color: 'gray', cursor: 'not-allowed', position: 'relative' }
              : {}
          "
          (mouseenter)="data.disabled && (showTooltip = true)"
          (mouseleave)="showTooltip = false"
        >
          {{ data.info }}
        </div>
      </div>
    </div>
  </div>
</div>
