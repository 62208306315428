
<div class="row threedViewer">
    <hr class="faq-border"/>
  </div>
  
  <div class="row threedViewer">
    <div class="columns helpTitle licenseHeading" id="help">Help
  
      <div class="columns">
        <br>
          <span class="normal-text">
            <a class="search-help-link vf-section-header__heading--is-link" href="https://www.ebi.ac.uk/training/online/courses/alphafold/ " target="_blank"
             (click)="gaService.eventEmitter('link_visit', 'training_href_click', 'click', 'training_entry_page_link', 'Clicks on the training link on every entry page')">
            <span style="display: inline-block; vertical-align: middle;">Visit our online training course</span>
            <span style="display: inline-block; vertical-align: middle; padding-top: 2px">
              <svg aria-hidden="true" class="vf-section-header__icon | vf-icon vf-icon-arrow--inline-end" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0C5.376.008.008 5.376 0 12zm13.707-5.209l4.5 4.5a1 1 0 010 1.414l-4.5 4.5a1 1 0 01-1.414-1.414l2.366-2.367a.25.25 0 00-.177-.424H6a1 1 0 010-2h8.482a.25.25 0 00.177-.427l-2.366-2.368a1 1 0 011.414-1.414z" fill="#3B6FB6" fill-rule="nonzero"></path>
              </svg>
            </span>
          </a>
          </span>
    
        <app-faqs-list [paeExplaination]="true"
        (click)="gaService.eventEmitter('pae-help-click', 'pae', 'click', accession, undefined)"></app-faqs-list>
        <app-faqs-list [tedExplaination]="true"
        (click)="gaService.eventEmitter('domains_help_click', 'training_href_click', 'click', accession, 'Clicks that expand the tutorial section of the Domains on the entry page')"></app-faqs-list>
      </div>
    </div>
  </div>
  
  <div class="row threedViewer">
    <div class="small-12">
      <h3 class="licenseHeading">Last updated</h3>
  <!--    <p class="licenseText">Last updated in AlphaFold DB version {{lastUpdateDate}}, created with the AlphaFold Monomer v2.0 pipeline.</p>-->
      <p class="licenseText">Last updated in AlphaFold DB version 2022-11-01, created with the AlphaFold Monomer v2.0 pipeline.</p>
    </div>
  </div>
  <div class="row">
    <div class="small-12">
      <h3 class="licenseHeading">Licence and attribution</h3>
      <p class="licenseText">
        Data is available for academic and commercial use, under a <a class="licenseLink"
                                                                      href="https://creativecommons.org/licenses/by/4.0/"
                                                                      target="_blank">CC-BY-4.0</a> licence.
        <br><br>
        EMBL-EBI expects attribution (e.g. in publications, services or products) for any of its online services, databases or software in accordance with good scientific practice.
        <br><br>
        If you make use of an AlphaFold prediction, please cite the following papers:<br class="hide-for-small-only">
        <a class="licenseLink" (click)="gaService.eventEmitter(
        'link_visit',
        'home_href_click',
        'click',
        'methods_paper',
        '')" href="https://www.nature.com/articles/s41586-021-03819-2" target="_blank">
          Jumper, J <i>et al</i>. Highly accurate protein structure prediction with AlphaFold. <i>Nature</i> (2021).</a><br>
          <a class="licenseLink" (click)="gaService.eventEmitter(
            'link_visit',
            'home_href_click',
            'click',
            'nar_paper',
            '')" href="https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkad1011/7337620"
               target="_blank" style="display:block; margin-top: 10px;">
              Varadi, M <i>et al</i>. 
              AlphaFold Protein Structure Database in 2024: providing structure coverage for over 214 million protein sequences. <i>Nucleic Acids Research</i> (2024).
              </a>
              <br>
              If you use data from AlphaMissense in your work, please cite the following paper:<br class="hide-for-small-only">
              <a class="licenseLink" (click)="gaService.eventEmitter(
              'link_visit',
              'home_href_click',
              'click',
              'methods_paper',
              '')" href="https://www.science.org/doi/10.1126/science.adg7492" target="_blank">
              Cheng, J et al. Accurate proteome-wide missense variant effect prediction with AlphaMissense. Science (2023).</a>
                   
        <br><br>
        AlphaFold Data Copyright (2022) DeepMind Technologies Limited.<br>
        AlphaMissense Copyright (2023) DeepMind Technologies Limited.
      </p>
    </div>
  </div>
  
  <div class="row ">
    <div class="small-12">
      <h3 class="licenseHeading">Feedback and questions</h3>
      <p class="licenseText">
        If you want to share feedback on an AlphaFold structure prediction, consider using the feedback buttons at the top of each structure page.
        If you have any questions that are not covered in the <a class="licenseLink" href="/faq" target="_blank">FAQs</a>, please contact
        <a class="licenseLink" href="mailto:alphafold@deepmind.com?subject={{accession}}">alphafold&#64;deepmind.com</a>.
        If you have feedback on the website or experience any bugs please
        contact <a class="licenseLink" href="mailto:afdbhelp@ebi.ac.uk?subject={{accession}}">afdbhelp&#64;ebi.ac.uk</a>.
      </p>
      <p class="licenseText">Let us know how the AlphaFold Protein Structure Database has been useful in your research at
        <a class="licenseLink"
           href="mailto:alphafold@deepmind.com?subject={{accession}}">alphafold&#64;deepmind.com</a>.</p>
    </div>
  </div>
  
  <div class="row updatedtextLast">
    <div class=" small-12">
      <h3 class="licenseHeading">Disclaimer</h3>
      <p class="licenseText">
        The AlphaFold and AlphaMissense Data and other information provided on this site contain predictions with varying levels of confidence, is for theoretical modelling only and caution should be exercised in its use. It is provided 'as-is' without any warranty of any kind, whether expressed or implied. For clarity, no warranty is given that use of the information shall not infringe the rights of any third party. The information is not intended to be a substitute for professional medical advice, diagnosis, or treatment, and does not constitute medical or other professional advice. The AlphaFold and AlphaMissense Data have not been validated for, and are not approved for, any clinical use. 
        <br><br>Use of the AlphaFold Protein Structure Database is subject to EMBL-EBI <a
        href="https://www.ebi.ac.uk/about/terms-of-use/" target="_blank" class="licenseLink">Terms of Use</a>.
      </p>
    </div>
  </div>