import { TedDomain } from './summary-text/summary-text.models';
import { colorGallery } from './constants';
type PDBeMolstar = any;

export async function colorByTed(
  viewerInstance: PDBeMolstar,
  tedDomainData: TedDomain[]
) {
  const tedData = [];
  tedDomainData.forEach((item, index) => {
    for (let seg of item.segments) {
      tedData.push({
        struct_asym_id: 'A',
        start_residue_number: seg.af_start,
        end_residue_number: seg.af_end,
        color: colorGallery[index % colorGallery.length],
      });
    }
  });
  const data = tedData;
  const selectionObject = {
    data,
    nonSelectedColor: '#F2F2F2',
  };
  await viewerInstance.visual.select(selectionObject);
}
