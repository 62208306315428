import * as loadedDotenv from '!val-loader!./dotenv-loader';

export interface IDotenv {
  baseUrl: string;
  uniprotApiUrl: string;
  uniprotEntryUrl: string;
  entryApiUrl: string;
  pdbeApiUrl: string;
  pdbeKbEntryUrl: string;
  googleAnalyticsTag: string;
  searchApiUrl: string;
  downloadApiUrl: string;
  suggestApiUrl: string;
  apiKey: string;
  swaggerApiUrl: string;
  clusterApiUrl: string;
  structureApiUrl: string;
  structureSearchApiUrl: string;
  pdbeBaseApiUrl: string;
  domainsApiUrl: string;
}

export const dotenv = loadedDotenv as IDotenv;
