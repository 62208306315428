<!--  *ngIf="seenInfo === 'false' || seenInfo === null" -->

<div [ngClass]="homePage ? 'infoBox-home' : 'infoBox'" *ngIf="seenInfo === 'false' || seenInfo === null">
  <div class="modalHeader">
    <span class="modalNewButton">New</span>
    <span class="modalTitle">Sequence similarity search</span>
    <span class="info-clear-icon" title="Clear search" 
      (click)="dismissModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white" style="background-color: white;">
        <path d="M10.2624 8.5665C10.1879 8.49218 10.1288 8.4039 10.0885 8.30671C10.0481 8.20952 10.0274 8.10532 10.0274 8.0001C10.0274 7.89487 10.0481 7.79068 10.0885 7.69348C10.1288 7.59629 10.1879 7.50801 10.2624 7.4337L15.648 2.0481C15.86 1.82062 15.9754 1.51974 15.9699 1.20886C15.9644 0.897978 15.8385 0.601362 15.6186 0.381501C15.3987 0.161641 15.1021 0.0357014 14.7912 0.0302163C14.4803 0.0247311 14.1795 0.140128 13.952 0.352096L8.56639 5.7377C8.49208 5.8122 8.4038 5.87131 8.30661 5.91164C8.20942 5.95197 8.10522 5.97273 8 5.97273C7.89477 5.97273 7.79057 5.95197 7.69338 5.91164C7.59619 5.87131 7.50791 5.8122 7.4336 5.7377L2.048 0.352096C1.82052 0.140128 1.51964 0.0247311 1.20876 0.0302163C0.897878 0.0357014 0.601261 0.161641 0.381401 0.381501C0.16154 0.601362 0.0356007 0.897978 0.0301155 1.20886C0.0246304 1.51974 0.140028 1.82062 0.351996 2.0481L5.7376 7.4337C5.8121 7.50801 5.87121 7.59629 5.91154 7.69348C5.95187 7.79068 5.97263 7.89487 5.97263 8.0001C5.97263 8.10532 5.95187 8.20952 5.91154 8.30671C5.87121 8.4039 5.8121 8.49218 5.7376 8.5665L0.351996 13.9521C0.234097 14.062 0.139533 14.1944 0.0739459 14.3416C0.00835879 14.4888 -0.0269081 14.6477 -0.029751 14.8089C-0.0325938 14.97 -0.00295439 15.13 0.0573992 15.2795C0.117753 15.4289 0.207584 15.5646 0.321534 15.6786C0.435484 15.7925 0.571218 15.8823 0.720639 15.9427C0.87006 16.003 1.03011 16.0327 1.19123 16.0298C1.35236 16.027 1.51126 15.9917 1.65846 15.9261C1.80566 15.8606 1.93814 15.766 2.048 15.6481L7.4336 10.2625C7.50791 10.188 7.59619 10.1289 7.69338 10.0886C7.79057 10.0482 7.89477 10.0275 8 10.0275C8.10522 10.0275 8.20942 10.0482 8.30661 10.0886C8.4038 10.1289 8.49208 10.188 8.56639 10.2625L13.952 15.6481C14.1795 15.8601 14.4803 15.9755 14.7912 15.97C15.1021 15.9645 15.3987 15.8386 15.6186 15.6187C15.8385 15.3988 15.9644 15.1022 15.9699 14.7913C15.9754 14.4805 15.86 14.1796 15.648 13.9521L10.2624 8.5665Z" fill="#54585A"/>
      </svg>
    </span>
  </div>

  <div class="modalInfo">
    <span>We have now implemented sequence-based searches to find structures. Sequence should be at least 20 amino acids and only standard residues are permitted.</span>
  </div>

  <div class="modalFooter">
    <span class="linkholder"><a target="_blank" (click)="dismissModal()" [routerLink]="['faq']" fragment="faq-8" routerLinkActive="active"
      (click)="gaService.eventEmitter(
'search_try_seq_click',
'search',
'click',
'seq_search_faq',
'Clicks on the Learn more in FAQ link on the new sequence similarity search popup')" class="learnLink">Learn more in FAQ
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33333 3.33333V12.6667H12.6667V8H14V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.59333 14 2 13.4 2 12.6667V3.33333C2 2.6 2.59333 2 3.33333 2H8V3.33333H3.33333ZM9.33333 3.33333V2H14V6.66667H12.6667V4.27333L6.11333 10.8267L5.17333 9.88667L11.7267 3.33333H9.33333Z" fill="#3B6FB6"/>
      </svg>
    </a></span>
    <button class="vf-button vf-button--secondary">
      <span class="vf-button__text"><a class="modalTryLink" (click)="dismissModal()" [routerLink]="['search','sequence','MENFQKVEKIGEGTYGVVYKARNKLTGEVVALKKIRLDTETEGVPSTAIREIS']" target="_blank">Try it out</a></span>

      <svg class="show-for-small-only vf-icon vf-icon--search-btn | vf-button__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 140 140" width="16" height="16">
          <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
            <path d="M23.414,20.591l-4.645-4.645a10.256,10.256,0,1,0-2.828,2.829l4.645,4.644a2.025,2.025,0,0,0,2.828,0A2,2,0,0,0,23.414,20.591ZM10.25,3.005A7.25,7.25,0,1,1,3,10.255,7.258,7.258,0,0,1,10.25,3.005Z" fill="#3B6FB6" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
          </g>
      </svg>

  </button>
  </div>
</div>


<div class="vf-form__item" [ngClass]="homePage ? 'inside-home' : ''">

    <label class="vf-form__label vf-u-sr-only | vf-search__label" for="searchitem">Search</label>
    <input #searchBox autocomplete="off"
        placeholder="Search for protein, gene, UniProt accession or organism or sequence search"
        (keyup)="search(searchBox.value)"
        (keyup.enter)="openSearch(searchBox.value)"
        id="searchitem" class="vf-form__input pdbeAutoCompleteSearchBox"
    />
    <span class="search-clear-icon" title="Clear search"
    (click)="clearSearch()" *ngIf="showClearIcon">
        <svg style="width:20px;height:20px;background-color: white;" viewBox="0 0 24 24">
          <path fill="#3B6FB6" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
    </span>
    <ul *ngIf="panelOpen && suggestions && suggestions.length > 0"
    id="vf-form--search__results-list" class="result-container vf-list | vf-form--search__results-list | vf-stack vf-stack--custom" aria-labelledby="searchitem">
        <li class="vf-list__item" role="option" *ngFor="let suggestion of suggestions">
            <a
            (click)="gaService.eventEmitter('search_suggester_click', 'search', 'click', suggestion, undefined)"
            href="./search/text/{{commonService.escapeValue(suggestion)}}?suggested=true"
            >{{suggestion}}</a>
        </li>
    </ul>

    <div class="result-container" *ngIf="panelOpen && (!suggestions ||  suggestions.length === 0) && processMessage" style="height: 40px; line-height: 40px; padding-left: 10px; overflow:hidden">
        {{processMessage}}
    </div>

</div>

<button id="searchButton" class="vf-search__button | vf-button vf-button--secondary" (click)="openSearch(searchBox.value)">
    <span class="hide-for-small-only vf-button__text">Search</span>

    <svg class="show-for-small-only vf-icon vf-icon--search-btn | vf-button__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 140 140" width="16" height="16">
        <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
          <path d="M23.414,20.591l-4.645-4.645a10.256,10.256,0,1,0-2.828,2.829l4.645,4.644a2.025,2.025,0,0,0,2.828,0A2,2,0,0,0,23.414,20.591ZM10.25,3.005A7.25,7.25,0,1,1,3,10.255,7.258,7.258,0,0,1,10.25,3.005Z" fill="#3B6FB6" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
        </g>
    </svg>

</button>



<div class="row column search-ex-links" [ngClass]="homePage ? 'inside-home' : ''">
    <div class="exampleContainer">
      Examples:
      <a class="search-ex-link" [routerLink]="['search','sequence','MENFQKVEKIGEGTYGVVYKARNKLTGEVVALKKIRLDTETEGVPSTAIREIS']">MENFQKVEKIGEGTYGV...</a>
      <a class="search-ex-link" [routerLink]="['search','uniprotDescription','Free fatty acid receptor 2']">Free fatty acid receptor 2</a>
      <a class="search-ex-link" [routerLink]="['search','text','At1g58602']">At1g58602</a>
      <a class="search-ex-link" [routerLink]="['search','text','Q5VSL9']">Q5VSL9</a>
      <a class="search-ex-link" [routerLink]="['search','text','Escherichia coli']">E. coli</a>
    </div>
  
    <div [ngClass]="homePage ? 'linksContainer' : 'inside-linksContainer'">

      <span class="vf-section-header__heading help-box" [ngStyle]="{'margin-right':homePage ? '15px' : '7px'}">
        <a class="search-help-link vf-section-header__heading--is-link" [routerLink]="['/faq']" fragment="faq-9"
           (click)="gaService.eventEmitter('search_faq_click', 'search', 'click', 'search_faq', undefined)">
          <span style="display: inline-block; vertical-align: middle;">See search help</span>
          <span style="display: inline-block; vertical-align: middle; padding-top: 2px; padding-right:13px; margin-left: -1px;">
            <svg aria-hidden="true" class="vf-section-header__icon | vf-icon vf-icon-arrow--inline-end" width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0C5.376.008.008 5.376 0 12zm13.707-5.209l4.5 4.5a1 1 0 010 1.414l-4.5 4.5a1 1 0 01-1.414-1.414l2.366-2.367a.25.25 0 00-.177-.424H6a1 1 0 010-2h8.482a.25.25 0 00.177-.427l-2.366-2.368a1 1 0 011.414-1.414z" fill="" fill-rule="nonzero"></path>
            </svg>
          </span>  
        </a>
      </span>
      
      <span class="vf-section-header__heading help-box" [ngStyle]="{'margin-right':homePage ? '15px' : '7px'}">
        <a class="search-help-link vf-section-header__heading--is-link" href="https://www.ebi.ac.uk/training/online/courses/alphafold/" target="_blank"
           (click)="gaService.eventEmitter('link_visit', 'training_href_click', 'click', 'training_search_link', 'Clicks on the training link next to search bar')">
          <span style="display: inline-block; vertical-align: middle;">Go to online course</span>
          <span style="display: inline-block; vertical-align: middle; padding-top: 2px">
            <svg aria-hidden="true" class="vf-section-header__icon | vf-icon vf-icon-arrow--inline-end" width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0C5.376.008.008 5.376 0 12zm13.707-5.209l4.5 4.5a1 1 0 010 1.414l-4.5 4.5a1 1 0 01-1.414-1.414l2.366-2.367a.25.25 0 00-.177-.424H6a1 1 0 010-2h8.482a.25.25 0 00.177-.427l-2.366-2.368a1 1 0 011.414-1.414z" fill="" fill-rule="nonzero"></path>
            </svg>
          </span>
        </a>
      </span> 
     
        <span *ngIf="homePage" class="vf-section-header__heading help-box-news" [ngStyle]="{'margin-right':homePage ? '15px' : '7px'}">
         
          <a class="search-help-link-news vf-section-header__heading--is-link"
             (click)="goToWhatsNew($event)" [routerLink]="['']" fragment="news">
            <span style="display: inline-block; vertical-align: middle;">See our updates – March 2025</span>
          </a>
          <svg *ngIf="isNewsSeen === 'false' || isNewsSeen === null" class="orange-dot" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
            <circle cx="3" cy="3" r="3" fill="#F49E17"/>
            </svg>
        </span> 
      </div>
    
    
</div>


<!--<div class="row column search-ex-links label-tooltip" [ngClass]="homePage ? 'inside-home' : 'inside-home-hide'">-->
<!--  <span class="search-feedback-link">-->
<!--      Feedback on structure:-->
<!--      <a class="search-ex-link"-->
<!--         href="https://docs.google.com/forms/d/e/1FAIpQLSfsM4wwADiUQwwohF-WkawMjtBEhlL6YcqzYXQFMexfl_59Vw/viewform?usp=sf_link"-->
<!--         (click)="gaService.eventEmitter('search_feedback_click', 'feedback', 'click', 'search_feedback', undefined)"-->
<!--         target="_blank">-->
<!--      Contact DeepMind</a>-->
<!--    </span>-->
<!--  <span class="label-tooltip-text hide-for-small-only" style="font-size: 14px; font-weight: 400; text-align: left;">-->
<!--          If you want to share your feedback on an AlphaFold structure prediction, please contact-->
<!--          DeepMind by clicking on this button.-->
<!--        </span>-->
<!--</div>-->
