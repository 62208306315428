import { ChangeDetectorRef, Injectable, inject, signal } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CommonService } from './common.service'
import { ConfigService } from './config.service';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {

  constructor(  private commonService: CommonService,
    private configService : ConfigService) {

  }
  private readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    responseType: 'json' as 'json',
  };

  subTimeout:any;

  isDownloadFailed =  signal(false);
  isDownloadDone =  signal(false);
  isDownloadRunning =  signal(false);

  isDownloadFailedAFDB =  signal(false);
  isDownloadDoneAFDB =  signal(false);
  isDownloadRunningAFDB =  signal(false);

  isDownloadFailedPDB =  signal(false);
  isDownloadDonePDB =  signal(false);
  isDownloadRunningPDB =  signal(false);

  allSelectedAFDB = signal(false);
  selectedValuesAFDB = signal([]);

  allSelectedPDB = signal(false);
  selectedValuesPDB = signal([]);

  allSelected = signal(false);
  selectedValues = signal([]);

  private hashedUrl = "";

  private readonly http = inject(HttpClient);

  public updateSelectedValue(selectedValues: string[]){
    this.selectedValues.set(selectedValues);
  }
  public updateSelectedValueSearch(selectedValues: string[], id:string){
    this.selectedValues.set(selectedValues);
    // localStorage.setItem(id, JSON.stringify(selectedValues));
    sessionStorage.setItem(id, JSON.stringify(selectedValues));
    //sessionStorage.setItem("selectedIds", JSON.stringify({id:id, selectedValues:selectedValues}));
  }

  public updateAllSelected(allSelected: boolean){
    this.allSelected.set(allSelected);
  }

  public updateSelectedValueAFDB(selectedValues: string[]){
    this.selectedValuesAFDB.set(selectedValues);
  }

  public updateAllSelectedAFDB(allSelected: boolean){
    this.allSelectedAFDB.set(allSelected);
  }

  public updateSelectedValuePDB(selectedValues: string[]){
    this.selectedValuesPDB.set(selectedValues);
  }

  public updateAllSelectedPDB(allSelected: boolean){
    this.allSelectedPDB.set(allSelected);
  }

  public initiateDownload(downloadUrl, downloadType, searchTerm, selectedValues): void {
    if(downloadType === "allresultscsv"){
      if (downloadUrl) {
        this.isDownloadRunning.set(true);
        this.downloadCSVFile(downloadUrl, searchTerm);
      } else {
        alert(`Don't have ${downloadType} csv file url from API`);
      }
    }
    if(downloadType === "modelcoordicate"){
      this.postFile(selectedValues, searchTerm);
    }
  }

  public downloadCSVFile(csvUrl: string, searchTerm) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob' as 'json'
    };

    this.http.get(csvUrl, options).subscribe(
      (response: Blob) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${searchTerm}-results-csv.csv`; // The name for the downloaded file
        document.body.appendChild(a);
        a.click();
        this.isDownloadRunning.set(false);
        this.isDownloadDone.set(true);
        if(this.isDownloadDone) {
          setTimeout(() => {
            this.isDownloadDone.set(false);
          }, 7000); 
        }
        this.clearSelectionSearch(searchTerm);
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        alert('There was an error downloading the file');
      }
    );
  }

  public postFile(selectedValues, searchTerm): void {
    const downloadApiUrl = this.configService.getConfig().downloadApiUrl;
    const auth = this.configService.getConfig().apiKey ? `?key=${this.configService.getConfig().apiKey}` : '';
    const postapiurl = `${downloadApiUrl}/submit/${auth}`;
    let fdsConfig;
    if(selectedValues){
      fdsConfig = {
        "ids": selectedValues,
        "files": ["mmcif", "pdb", "pae"]
      }
    }
    this.isDownloadRunningAFDB.set(true);
    this.isDownloadRunning.set(true);
    this.commonService.postApiData(postapiurl, fdsConfig).subscribe(async (response) => {
      if(response && (response.status == 200)){
        if(response && response.job_id){
          this.getFileLinkForDownload(response.job_id, searchTerm);
        }
      }
    },
    (error) => {
      const code =  error.status;
      this.isDownloadRunning.set(false);
      this.isDownloadFailed.set(true);
      this.isDownloadRunningAFDB.set(false);
      this.isDownloadFailedAFDB.set(true);
      // this.changeDetectorRef.detectChanges();
    });
  }
  
  private getFileLinkForDownload(job_id, searchTerm){
    const downloadApiUrl = this.configService.getConfig().downloadApiUrl;
    const auth = this.configService.getConfig().apiKey ? `?key=${this.configService.getConfig().apiKey}` : '';
    const getapiurl = `${downloadApiUrl}/status/${job_id}${auth}`;
      this.commonService.getApiDatawithStatus(getapiurl).subscribe(async (response: HttpResponse<any>) => {
      const code = response.status;
      if(response && response.body.message){
        if(code === 202){
          if(response.body.message && (response.body.message.startsWith("Job still running")))
          {
            this.isDownloadRunningAFDB.set(true);
            this.isDownloadRunning.set(true);
            this.subTimeout = setTimeout(() => {
              this.getFileLinkForDownload(job_id, searchTerm);
            }, 10000);
          }
        }
        if(code === 200 && response.body.url != ""){
          this.downloadFileAFDB(response.body.url, searchTerm);
        }
      } 
    },
    err => {
      this.isDownloadRunning.set(false);
      this.isDownloadFailed.set(true);
      this.isDownloadRunningAFDB.set(false);
      this.isDownloadFailedAFDB.set(true);
    });
  }

  public downloadFileAFDB(url: string, searchTerm: string){
    if (!url) {
      console.error("Invalid URL provided");
      return;
    }
    const link = document.createElement('a');
    const fileName = `${searchTerm}-modeldata.zip`;
    link.download = fileName;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    this.isDownloadRunningAFDB.set(false);
    this.isDownloadDoneAFDB.set(true);
    this.isDownloadRunning.set(false);
    this.isDownloadDone.set(true);
    if(this.isDownloadDoneAFDB) {
      setTimeout(() => {
        this.isDownloadDoneAFDB.set(false);
      }, 7000); 
    }
    if(this.isDownloadDone) {
      setTimeout(() => {
        this.isDownloadDone.set(false);
      }, 7000); 
    }
    this.clearSelection('afdb');
    this.clearSelectionSearch(searchTerm);
  }


  closeToast(): void {
    this.isDownloadFailed.set(false);
    this.isDownloadDone.set(false);
    this.isDownloadFailedAFDB.set(false);
    this.isDownloadDoneAFDB.set(false);
    this.isDownloadFailedPDB.set(false);
    this.isDownloadDonePDB.set(false);
  }

  clearSelection(flag) {
    if(flag === 'afdb'){
      this.allSelectedAFDB.set(false);
      this.selectedValuesAFDB.set([]);
      this.allSelected.set(false);
      this.selectedValues.set([]);
    }
    if(flag === 'pdb'){
      this.allSelectedPDB.set(false);
      this.selectedValuesPDB.set([]);
    }
  }

  clearSelectionSearch(searchTerm) {
    this.allSelected.set(false);
    this.selectedValues.set([]);
    this.allSelectedAFDB.set(false);
    this.selectedValuesAFDB.set([]);
    sessionStorage.setItem(searchTerm, JSON.stringify([])); 
    sessionStorage.removeItem(searchTerm);
  }

  public postPDBFile(selectedValues: string[], searchTerm): void {
    const postapiurl =  `${this.configService.getConfig().pdbeBaseApiUrl}/download/api/pdb/entry/all`;
    let fdsConfig;
    if(selectedValues){
      fdsConfig = {
        "ids": selectedValues
      }
    }
    this.isDownloadRunningPDB.set(true);
    this.commonService.postApiData(postapiurl, fdsConfig).subscribe(async (response) => {
      if(response && (response.status == 200)){
        if(response && response.url){
          this.getDownloadFilePDB(response.url, searchTerm);
        }
      }
    },
    (error) => {
      const code =  error.status;
      this.isDownloadRunningPDB.set(false);
      this.isDownloadFailedPDB.set(true);
    });
  }

  private getDownloadFilePDB(getapiurl: string, searchTerm: string) {
    fetch(getapiurl)
      .then((response) => { 
        if (response.status === 202) {
          this.isDownloadRunningPDB.set(true);
          this.isDownloadRunning.set(true);
          setTimeout(() => {
            this.getDownloadFilePDB(getapiurl, searchTerm);
          }, 10000); 
        } if(response.status === 200){
          this.isDownloadRunningPDB.set(false);
          this.isDownloadRunning.set(false);
          return response.blob();
        }
        if(response.status === 500 || response.status === 400 || response.status === 429 ||  response.status === 0){
         throw Error;
        }
      })
      .then((blob) => {
        if(blob !== undefined){
          this.downloadFilePDB(blob, searchTerm);
        }
      })
      .catch((error) => {
        this.isDownloadRunning.set(false);
        this.isDownloadFailed.set(true);
        this.isDownloadRunningPDB.set(false);
        this.isDownloadFailedPDB.set(true);
        this.clearSelection('pdb');
      });
  }
  private downloadFilePDB(blobResponse: Blob, searchTerm) {
    const blob = new Blob([blobResponse], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${searchTerm}-modeldata.zip`; 
    document.body.appendChild(a);
    a.click(); 
    document.body.removeChild(a);
  
    window.URL.revokeObjectURL(url);
    this.isDownloadRunningPDB.set(false);
    this.isDownloadDonePDB.set(true);
    if(this.isDownloadDonePDB) {
      setTimeout(() => {
        this.isDownloadDonePDB.set(false);
      }, 7000); 
    }
    this.clearSelection('pdb');
  }
}
