import { Component, Input, OnInit } from '@angular/core';
import { SummaryText, SummaryData } from './summary-text.models';
import { EntryData } from '../entry/structure-data.models';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { CommonService } from '../common.service';
import { ConfigService } from '../config.service';
import { EntryStatusComponent } from '../entry-status/entry-status.component';
// import { sum } from 'd3';

@Component({
  selector: 'app-summary-text',
  templateUrl: './summary-text.component.html',
  styleUrls: ['./summary-text.component.css'],
})
export class SummaryTextComponent implements OnInit {
  @Input() accession: string;
  // @Input() data: SummaryData;
  data: SummaryData;
  @Input() entryData: EntryData;
  @Input() csvAmData: any;
  @Input() csvAMFileUrl: any;
  
  infoSectionHeight: any = { maxHeight: '1000px', overflow: 'hidden' };
  showInfoSection = true;
  uniprotData: any;
  kbCount = 0;
  // summaryData: SummaryData;

  constructor(
    public commonService: CommonService,
    private gaService: GoogleAnalyticsService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.getPdbeData();
    this.getUniProtData();
  }

  /**
   * Cuts the text to a specified number of words and add ... if needed
   * @param text - any text to be displayed
   */
  getShortenedText(text: string): string {
    if (!text) {
      return;
    }
    const words = text.split(' ');
    if (words.length <= this.data.text_limit) {
      return text;
    }
    const wordSubset = words.slice(0, this.data.text_limit);
    return wordSubset.join(' ') + ' ...';
  }

  /**
   * Toggle if a text should be shown completely or only in a shortened
   * version
   * @param text - a text object that has a .show_long attribute
   */
  switchText(text: SummaryText): boolean {
    text.show_long = !text.show_long;
    return text.show_long;
  }

  /**
   * Check if a text is longer than the limit
   * @param text - a text object
   */
  checkIfHasToShowMore(text: SummaryText): boolean {
    const words = text.text.split(' ');
    return !text.show_long && words.length >= this.data.text_limit;
  }

  async downloadClick(e, eventName: string, eventLabel: string): Promise<any> {
    if(eventLabel === 'AM_downloading') {
      if(this.csvAMFileUrl){
        this.downloadCSVFile(this.csvAMFileUrl);
      }else {
        alert("Dont have csv file url from API");
      }
      
    } else {
      e.target.blur();
    }
    this.gaService.eventEmitter(
      eventName,
      'download_click',
      'click',
      eventLabel,
      undefined
    );
  }

  downloadCSVFile(csvurl) {
    const link = document.createElement('a')
    link.href = csvurl
    link.download = `${this.accession}.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(csvurl)
  }

  toggleInfoSection(): void {
    if (this.showInfoSection) {
      this.infoSectionHeight = Object.assign(
        {},
        {
          maxHeight: '0px',
          marginTop: '0px',
          paddingBottom: '0px',
          overflow: 'hidden',
        }
      );
    } else {
      this.infoSectionHeight = Object.assign(
        {},
        { maxHeight: '1000px', paddingBottom: '16px', overflow: 'hidden' }
      );
    }
    this.showInfoSection = !this.showInfoSection;
  }

  getUniProtData(): void {
    this.commonService
      .getApiData(this.configService.getConfig().uniprotApiUrl + this.accession)
      .subscribe((uniProtData) => {
        this.uniprotData = uniProtData;
          this.uniprotSummaryData();
      });
    this.createSummaryData();
  }

  getPdbeData(): void {
    const solrApiUrl = `https://www.ebi.ac.uk/pdbe/search/pdb/select?group=true&group.field=pdb_id&group.ngroups=true&group.facet=true&facet=true&rows=0&facet.limit=5&facet.offset=0&facet.mincount=1&facet.sort=count&omitHeader=true&json.nl=map&facet.field=uniprot_accession&facet.threads=-1&q=uniprot_accession:${this.accession}&wt=json`;
    this.commonService.getApiData(solrApiUrl).subscribe((apiData) => {
      if (apiData && apiData.grouped && apiData.grouped.pdb_id) {
        this.kbCount = apiData.grouped.pdb_id.matches;
        this.updateKbData();
      }
    });
  }

  createSummaryData(): void {
    const dataItem = this.entryData;
    const summary = {
      title: '',
      subtitle: '',
      texts: [],
      text_limit: 70,
      flags: [],
      isReferenceProteome: false,
      isReviewed: false,
      afdb_accession: ''
    };

    summary.title = dataItem.uniprotDescription;
    summary.subtitle = 'AlphaFold structure prediction';
    summary.isReferenceProteome = dataItem.isReferenceProteome;
    summary.isReviewed = dataItem.isReviewed;
    summary.afdb_accession = `${dataItem.entryId}-v${dataItem.latestVersion}`;

    // if (dataItem.entryId) {
    //   summary.texts.push({
    //     label: 'Status',
    //     data: {
    //       isReferenceProteome : dataItem.isReferenceProteome,
    //       isReviewed : dataItem.isReviewed
    //     },
    //     italic: false,
    //     show_long: true,
    //     source: '',
    //     source_url: ''
    //   });
    // }
    if (dataItem.entryId) {
      summary.texts.push({
        label: 'Protein',
        text: dataItem.uniprotDescription,
        italic: false,
        show_long: true,
        source: '',
        source_url: '',
      });
    }
    if (dataItem.entryId) {
      const geneName = dataItem.gene ? dataItem.gene : 'Not available';
      summary.texts.push({
        label: 'Gene',
        text: geneName,
        italic: false,
        show_long: true,
        source: '',
        source_url: '',
      });
    }

    // Add common name after latin name, if available
    if (dataItem.organismScientificName) {
      summary.texts.push({
        label: 'Source organism',
        text: dataItem.organismScientificName,
        searchText: dataItem.organismScientificName,
        italic: true,
        show_long: true,
        source: '',
        source_url: '',
      });
    }
    if (dataItem.uniprotAccession) {
      summary.texts.push({
        label: 'UniProt',
        text: dataItem.uniprotAccession,
        italic: false,
        show_long: true,
        source: 'UniProt',
        source_url:
          this.configService.getConfig().uniprotEntryUrl +
          dataItem.uniprotAccession,
      });
    }

    // Add KB data
    const kbText = 'Loading...'
      // this.kbCount === 0
      //   ? 'None available in the PDB'
      //   : `${this.kbCount} structure${this.kbCount > 1 ? 's' : ''} in PDB for ${
      //       this.accession
      //     }`;
    const kbSource = '' //this.kbCount === 0 ? '' : 'PDBe-KB';
    const kbUrl = ''
      // this.kbCount === 0
      //   ? ''
      //   : this.configService.getConfig().pdbeKbEntryUrl + this.accession;
    summary.texts.push({
      label: 'Experimental structures',
      text: kbText,
      italic: false,
      show_long: true,
      source: kbSource,
      source_url: kbUrl,
    });
    this.data = summary;
 }
  updateKbData(): void {
    // Add KB data
    const kbText =
      this.kbCount === 0
        ? 'None available in the PDB'
        : `${this.kbCount} structure${this.kbCount > 1 ? 's' : ''} in PDB for ${
          this.accession
        }`;
    const kbSource = this.kbCount === 0 ? '' : 'PDBe-KB';
    const kbUrl =
      this.kbCount === 0
        ? ''
        : this.configService.getConfig().pdbeKbEntryUrl + this.accession;
    const kbdata = {
      label: 'Experimental structures',
      text: kbText,
      italic: false,
      show_long: true,
      source: kbSource,
      source_url: kbUrl,
    };
    const kbidx = this.data.texts.findIndex((o, i) => o.label === 'Experimental structures');
    this.data.texts[kbidx] = {...kbdata}
  }

  uniprotSummaryData(): void {
    // Check if common name exists in UniProt data
    let commonName = '';
    if (
      this.uniprotData &&
      this.uniprotData.organism &&
      this.uniprotData.organism.names
    ) {
      const names = this.uniprotData.organism.names;
      names.forEach((name) => {
        if (name.type && name.type === 'common') {
          commonName = name.value;
        }
      });
      const sourceOrganism = this.data.texts[2];
      sourceOrganism.text = commonName
        ? sourceOrganism.text + ' (' + commonName + ')'
        : sourceOrganism.text;
    }

    // Default biological function text
    const bioFunction: SummaryText = {
      label: 'Biological function',
      text: 'Not available',
      italic: false,
      show_long: false,
      source: 'UniProt',
      source_url: 'https://www.uniprot.org/uniprot/',
    };
    let catalyticActivity: string;
    // Set biological function and catalytic activity
    if (this.uniprotData.comments) {
      bioFunction.text = this.uniprotData.comments[0].text[0].value;
      this.uniprotData.comments.forEach((comment) => {
        // if (comment.type === 'FUNCTION') {
        //   bioFunction.text = comment.text[0].value;
        // }
        if (comment.type === 'CATALYTIC_ACTIVITY') {
          catalyticActivity = comment.reaction.name;
        }
      });
      bioFunction.text += '.';
    }
    bioFunction.source_url += this.accession;
    if (bioFunction.text === 'Not available' && catalyticActivity !== '') {
      bioFunction.text = 'Catalytic activity: ' + catalyticActivity;
    }
    if (bioFunction.text !== 'Not available') {
      const pubmed = 'PubMed:([0-9]+)';
      const re = new RegExp(pubmed, 'g');
      bioFunction.text = bioFunction.text.replace(
        re,
        '<a href="https://www.uniprot.org/citations/$1" target="_blank">PubMed:$1</a>'
      );
    }
    this.data.texts.push(bioFunction);
  }
}
