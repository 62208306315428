<div class="popup">
  <button class="close-btn" *ngIf="!isTooltip()" (click)="onClosePopup()">
    <img src="assets/img/close.svg" alt="close icon" />
  </button>
  <p><span>Domain</span> {{ activeDomain()?.tedNumber }}</p>
  <ng-container *ngIf="activeDomain().label === '-'">
    <p>
      <span>CATH</span>
      No match
    </p>
  </ng-container>
  <ng-container *ngIf="activeDomain().label !== '-'">
    <p>
      <span>CATH</span>
      <a
        [href]="
          'https://www.cathdb.info/version/v4_4_0/cathnode/' +
          activeDomain().label
        "
        target="_blank"
        (click)="gaService.eventEmitter('CATH_clickthrough', 'domains', 'click', 'CATH_url', 'Clicks on CATH link from Domains info tooltip')"
        >{{ activeDomain()?.label }} <svg class="targetbl" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33333 3.33333V12.6667H12.6667V8H14V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.59333 14 2 13.4 2 12.6667V3.33333C2 2.6 2.59333 2 3.33333 2H8V3.33333H3.33333ZM9.33333 3.33333V2H14V6.66667H12.6667V4.27333L6.11333 10.8267L5.17333 9.88667L11.7267 3.33333H9.33333Z" fill="#3B6FB6"/>
          </svg></a
      >
    </p>
  </ng-container>
  <p><span>Quality</span> {{ activeDomain()?.quality }} 
    <span *ngIf="activeDomain().quality === 'High-confidence'">
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path d="M3.232 14.272L4.944 9.184L0.816 6.416H5.872L7.68 0.959999L9.52 6.416H14.544L10.416 9.184L12.16 14.272L7.68 11.024L3.232 14.272Z" fill="#1A1C1A"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path d="M3.232 14.272L4.944 9.184L0.816 6.416H5.872L7.68 0.959999L9.52 6.416H14.544L10.416 9.184L12.16 14.272L7.68 11.024L3.232 14.272Z" fill="#1A1C1A"/>
      </svg>
    </span>
    <span *ngIf="activeDomain().quality === 'Moderate'">
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path d="M3.232 14.272L4.944 9.184L0.816 6.416H5.872L7.68 0.959999L9.52 6.416H14.544L10.416 9.184L12.16 14.272L7.68 11.024L3.232 14.272Z" fill="#1A1C1A"/>
      </svg>
    </span>
    <span *ngIf="activeDomain().quality === 'Uncertain'">
     
    </span>
  </p>
  <div class="innerpart">
    <p class="innerpara"><span class="innertext">Qscore: {{ activeDomain()?.qscore }} </span></p>
    <p class="innerpara"><span class="innertext">Assignment: {{ activeDomain().cath_assignment_level === '-' ? 'No assignment' : activeDomain().cath_assignment_level }} </span> </p>

  </div>

  <p><span>Boundaries</span> {{ activeDomain()?.boundaries }}</p>
  <p>
    <span>Length</span>
    {{ activeDomain()?.length }}
  </p>
  <p>
    <span>Average pLDDT</span>
    {{ activeDomain()?.plddt | number: '1.2-2' }}
  </p>

  <p><a (click)="gaService.eventEmitter('link_visit', 'training_href_click', 'click', 'domains_faq_link', 'Click on the FAQ link in Domains info tooltip')"  class="learnlink" [routerLink]="['/faq']" fragment="faq-27" target="_blank" routerLinkActive="active">Learn more about the TED data</a></p>

</div>
