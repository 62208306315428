import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Input,
  OnChanges,
  Output,
  signal,
} from '@angular/core';
import {
  applyAlphaMissenseTooltips,
  colorByAlphaMissense,
  resetColoring,
  resetTooltips,
} from '../molstar-alpha-missense';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { TedDomain } from '../summary-text/summary-text.models';
import { UtilService } from '../util.service';
import { colorByTed } from '../molstar-alpha-ted';

const COUNT_LEGENDS = 12;

@Component({
  selector: 'app-structure-viewer',
  templateUrl: './structure-viewer.component.html',
  styleUrl: './structure-viewer.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StructureViewerComponent implements OnChanges {
  @Input() isModalConf: boolean;
  @Input() isAlphaMis: boolean;
  @Input() isShowModelLegendsText: boolean;
  @Input() isShowModelLegends: boolean;

  @Input() accession: string;
  @Input() csvAmData: any;
  @Input() pdbeMolstar: any;
  @Output() selectedToggle: EventEmitter<Object> = new EventEmitter();

  public dropdownOptions: { label: string, value: any, isShow: boolean, disabled?: boolean, info?: string }[];

  public tedDomainData = input.required<TedDomain[]>();
  public isTedDomain = signal<boolean>(false);
  public isTedDomainMoreThanThree = computed(
    () => this.tedDomainData().length > COUNT_LEGENDS
  );
  public slicedTedDomain = signal<TedDomain[]>([]);
  public showMoreLegends = signal<boolean>(false);
  public dataIcon = signal<string>('&#xf078;');
  public legendText = signal<string>('');
  public wrapperWidth = signal<string>('100%');
  public wrapperItemFlexBasis = signal<string>('33.33%');
  public selectedTab = 'plddt';

  public dropdownOpen = signal<boolean>(false);
  public dropdownOptionSelected = signal<string>('Model Confidence');

  rangeFilter: any;

  viewerLegend: any = [
    { style: { backgroundColor: '#0053D6' }, label: 'Very high (pLDDT > 90)' },
    { style: { backgroundColor: '#65CBF3' }, label: 'High (90 > pLDDT > 70)' },
    { style: { backgroundColor: '#FFDB13' }, label: 'Low (70 > pLDDT > 50)' },
    { style: { backgroundColor: '#FF7D45' }, label: 'Very low (pLDDT < 50)' },
  ];

  alphamissenseLegends: any = [
    { style: { backgroundColor: '#2166ac' }, label: 'Likely benign' },
    { style: { backgroundColor: '#A8A9AC' }, label: 'Uncertain' },
    { style: { backgroundColor: '#b2182b' }, label: 'Likely pathogenic' },
  ];

  constructor(
    public gaService: GoogleAnalyticsService,
    public readonly utilService: UtilService
  ) {
    this.utilService.domainTrackClicked.subscribe((state) => {
      if (state === 'active') {
        this.selectedTab = 'ted';
        this.dropdownOptionSelected.set('TED Domains');
        this.applyMolstarColoring('ted');
      }
    });
  }

  ngOnChanges(): void {
    this.slicedTedDomain.update(() =>
      this.tedDomainData()?.slice(0, COUNT_LEGENDS)
    );
    this.legendText.set(`Show all ${this.tedDomainData()?.length} domains`);
  }

  public toggleDropdown(): void {
    this.dropdownOpen.update((state) => !state);
  }

  public updateDropdownView(): void {
    this.dropdownOpen.set(false);
  }

  applyMolstarColoring(kind: 'plddt' | 'missense' | 'ted') {
    if (kind === 'missense') {
      this.isAlphaMis = true;
      this.isModalConf = false;
      this.isTedDomain.set(false);
      this.selectedToggle.emit({
        isAlphaMis: this.isAlphaMis,
        isModalConf: this.isModalConf,
        isTedDomain: this.isTedDomain,
      });
      this.dropdownOptionSelected.set('AlphaMissense Pathogenicity');
      if (this.rangeFilter) {
        colorByAlphaMissense(
          this.pdbeMolstar,
          this.csvAmData,
          this.rangeFilter
        );
      } else {
        colorByAlphaMissense(this.pdbeMolstar, this.csvAmData);
      }
      applyAlphaMissenseTooltips(this.pdbeMolstar, this.csvAmData);
      this.gaService.eventEmitter(
        'AM_toggle',
        'AlphaMissense',
        'click',
        'AM_toggle',
        'Toggle between AM and pLDDT colouring on Mol*'
      );
    } else if (kind === 'plddt') {
      this.isAlphaMis = false;
      this.isModalConf = true;
      this.isTedDomain.set(false);
      this.selectedToggle.emit({
        isAlphaMis: this.isAlphaMis,
        isModalConf: this.isModalConf,
        isTedDomain: this.isTedDomain,
      });
      this.dropdownOptionSelected.set('Model Confidence');
      resetColoring(this.pdbeMolstar);
      resetTooltips(this.pdbeMolstar);
    } else if (kind === 'ted') {
      this.setUpTEDToggleUI();
      this.dropdownOptionSelected.set('TED Domains');
      colorByTed(this.pdbeMolstar, this.tedDomainData());
      this.gaService.eventEmitter(
        'domains_toggle',
        'domains',
        'click',
        'domains_toggle',
        'Toggle between TED colouring on Mol*'
      );
    } else {
      console.error('Unknow coloring kind:', kind);
    }
  }

  private setUpTEDToggleUI(): void {
    this.isAlphaMis = false;
    this.isModalConf = false;
    this.isTedDomain.set(true);
    this.selectedToggle.emit({
      isAlphaMis: this.isAlphaMis,
      isModalConf: this.isModalConf,
      isTedDomain: this.isTedDomain,
    });
  }

  public onShowMoreLegends(): void {
    this.wrapperWidth.set('100%');
    this.wrapperItemFlexBasis.set(this.showMoreLegends() ? '33.33%' : '11.11%');
    this.showMoreLegends.update((value) => !value);
    if (this.showMoreLegends()) {
      this.slicedTedDomain.update(() => this.tedDomainData());
      this.legendText.set('Show less');
      this.dataIcon.set('&#xf077;');
    } else {
      this.slicedTedDomain.update(() =>
        this.tedDomainData().slice(0, COUNT_LEGENDS)
      );
      this.legendText.set(`Show all ${this.tedDomainData().length} domains`);
      this.dataIcon.set('&#xf078;');
    }
    this.gaService.eventEmitter(
      'Show_more_legends',
      'Legends',
      'click',
      'Show_more_legends'
    );
  }
}
