import { Component, OnInit, ElementRef, OnDestroy, ChangeDetectorRef, SimpleChanges, inject, DestroyRef, signal } from '@angular/core';
import {Router, ActivatedRoute, NavigationExtras, Params} from '@angular/router';
import { CommonService } from '../common.service';
import { ConfigService } from '../config.service';
import {Location} from '@angular/common';
import { EMPTY, Subscription, SubscriptionLike, catchError, map } from 'rxjs';
import {GoogleAnalyticsService} from '../google-analytics.service';
import {FilterData} from "./Interfaces";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DownloadService } from "../download-service"

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {
  
  searchField: string;
  searchTerm: string;
  sortBy: string = "HSP score: highest first";
  selectedFilters: {
    organismScientificName: string[],
    isReviewed: string[],
    isReferenceProteome: string[],
    globalMetricValue: any
  };
  // filters: string[];
  filters: {
    isReviewed: string,
    isReferenceProteome: string,
    common_organisms: string,
    organismScientificName: string,
    globalMetricValue: any
  };
  filterData: FilterData;
  filterLabels: any;
  mainResultData: any;
  paginationData: any;
  loadComplete: boolean;
  routeSubscriber: Subscription;
  locSubscriber: SubscriptionLike;
  isFilterListOpen: boolean;
  isSuggested: boolean;
  queryParams: Params;
  isRangeFilterResultsEmpty: boolean =  false;

  minplddt: number = 0;
  maxplddt: number = 100;

  subTimeout:any;
  isSequenceSearch: boolean = false;
  isSearchprogress: boolean = false;
  isNoresult: boolean = false;
  isCopySequence: boolean = false;
  isCopyLink: boolean = false;
  localStorageSearchTerm: string;
  
  selectedItems: boolean[] = []; // Array to track selection state for each item

  readonly MAX_SELECTION = 100;
  readonly PAGE_SIZE = 20;
  isLimitReached = false;

  pageSelections: { [key: number]: boolean[] } = {}; // Tracks selections per page (e.g., {0: [true, false, ...]})
  currentPage = 0;

  private readonly downloadService = inject(DownloadService);
  selectedValues = this.downloadService.selectedValues;
  allSelected= this.downloadService.allSelected;

  errorEntryText: any;
  hashedUrl: any;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private location: Location,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private el: ElementRef,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    public gaService: GoogleAnalyticsService) {}
    dropdownOptions: { label: string, value: any, isShow: boolean, disabled?: boolean, info?: string }[];

  ngOnInit(): void {
    //this.downloadService.updateSelectedValueSearch([]); // Global array for all selected items across pages
   
    this.selectedItems = new Array(this.PAGE_SIZE).fill(false);
    this.dropdownOptions = [
      { label: 'Search results (max 10 000)', value: "allresultscsv" , isShow: true, disabled: false },
      { label: 'Coordinates and PAE (max 100)', value: "modelcoordicate", isShow: true, disabled: true },
    ];

    this.loadComplete = false;
    this.filterLabels = {
      isReviewed: 'Review',
      isReferenceProteome: 'Reference proteome',
      common_organisms: 'Popular',
      organismScientificName: 'Other organisms',
      globalMetricValue: 'Average pLDDT score'
    };
    this.filters = {
      isReviewed: 'isReviewed',
      isReferenceProteome: 'isReferenceProteome',
      common_organisms: 'organismScientificName',
      organismScientificName: 'organismScientificName',
      globalMetricValue: 'globalMetricValue'
    }
    this.route.queryParams.subscribe(
      queryParams => {
        this.initResultData();
        this.queryParams = queryParams;
        if(this.queryParams.globalMetricValue !== ""){
          const rangeStr = this.queryParams.globalMetricValue;
          if(rangeStr){
            const { min, max } = this.extractMinMax(rangeStr);
            this.minplddt = min;
            this.maxplddt = max;
          }else{
            this.minplddt = 0;
            this.maxplddt = 100;
          }
        } 
        this.changeDetectorRef.detectChanges();
        this.transformQueryParamsToSelectedFilters(queryParams);
      }
      
    )
    // get url params
    this.routeSubscriber = this.route.params.subscribe(params => {
      this.loadComplete = false;
      this.initResultData();
      this.searchField = params.field;
      this.searchTerm = this.replaceEscapedSlash(params.term);
      this.processQueryParams(this.location.path());
      if(this.searchField === "sequence"){
        this.isSequenceSearch = true;
        this.makeSolrRequest('main');
      }else{
        this.isSequenceSearch = false;
        this.makeSolrRequest('main');
        this.makeSolrRequest('facets');
      }

    });

    if(sessionStorage[this.searchTerm]){
      const ids = JSON.parse(sessionStorage.getItem(this.searchTerm));
      this.downloadService.updateSelectedValueSearch(ids, this.searchTerm);
    }else{
      this.downloadService.updateSelectedValueSearch([], this.searchTerm);
    }
    // subscribe to location observable for 'Back' button functionality
    this.locSubscriber = this.location.subscribe(params => {
      this.loadComplete = false;
      this.initResultData();
      this.processQueryParams(params.url);

      if(this.searchField === "sequence"){
        this.makeSolrRequest('main');
      }else{
        this.makeSolrRequest('main');
        this.makeSolrRequest('facets');
      }

    });
  }
  get isEntrySelected() : boolean {
    const selected = this.selectedItems.filter(isSelected => isSelected).length;
    return selected > 0 ? false : true ;
  }
  get selectedCount(): number {
    return this.selectedItems.filter(isSelected => isSelected).length;
  }

  isAllSelectedAFDB(): boolean {
    const startIndex = 0;
    const endIndex = 20;
    const visibleRows = this.mainResultData.slice(startIndex, endIndex);
    const isallselectedafdb = visibleRows.every(row => this.selectedValues().includes(row.uniprotAccession))
    this.downloadService.updateAllSelected(isallselectedafdb);
    return isallselectedafdb; // Check by ID
  }
  // Toggle Select All
  toggleSelectAll(event: any) {
    // this.allSelected = event.target.checked;
    this.downloadService.updateAllSelected(event.target.checked);
    if (!this.pageSelections[this.currentPage]) {
        this.initializePageSelections(this.currentPage);
    }
    // Loop through current page entries to set selection
    
    this.pageSelections[this.currentPage].forEach((_, index) => {
      if(this.mainResultData.length > index){
        const itemValue = this.mainResultData[index].uniprotAccession;
        
        if (this.allSelected() && this.selectedValues().length < this.MAX_SELECTION) {
          this.pageSelections[this.currentPage][index] = true;
          // let selectedValues = [];
          if (!this.selectedValues().includes(itemValue)) {
              const selectedValues = [...this.selectedValues(), itemValue];
              this.downloadService.updateSelectedValueSearch(selectedValues, this.searchTerm);
          }
          const selectedValues = this.selectedValues();
         
          this.downloadService.updateSelectedValueSearch(selectedValues, this.searchTerm);

        } else {
          this.pageSelections[this.currentPage][index] = false;
          const selectedValues = this.selectedValues().filter(value => value !== itemValue);
          this.downloadService.updateSelectedValueSearch(selectedValues, this.searchTerm);
        }
      }
    });
    this.isAllSelectedAFDB();
    this.checkSelectionLimit();
  }

  onItemSelectionChanged(event: { value: string, selected: boolean }, index: number) {
    // Ensure pageSelections for the current page is initialized
    if (!this.pageSelections[this.currentPage]) {
      this.initializePageSelections(this.currentPage);
    }
    this.pageSelections[this.currentPage][index] = event.selected;

    if (event.selected) {
      if (!this.selectedValues().includes(event.value) && this.selectedValues().length < this.MAX_SELECTION) {
        const selectedValues = [...this.selectedValues(), event.value]
        this.downloadService.updateSelectedValueSearch(selectedValues, this.searchTerm);
        this.checkSelectionLimit();
      }
    } else {
      const selectedValues = this.selectedValues().filter(value => value !== event.value);
      this.downloadService.updateSelectedValueSearch(selectedValues, this.searchTerm);
    }
    this.changeDetectorRef.detectChanges();
    const allSelected = this.pageSelections[this.currentPage].every(selected => selected);
    // this.downloadService.updateAllSelected(allSelected);
    this.isAllSelectedAFDB();
    this.checkSelectionLimit();
  }

  checkSelectionLimit() {
    this.isLimitReached = this.selectedValues().length >= this.MAX_SELECTION;
  }

  clearSelection() {
    this.downloadService.updateAllSelected(false);
    Object.keys(this.pageSelections).forEach(page => this.pageSelections[page].fill(false));
    
    this.downloadService.updateSelectedValueSearch([], this.searchTerm);
    this.isLimitReached = false;
    this.selectedItems.fill(false);
    this.changeDetectorRef.detectChanges();
  }

  handleSelection(downloadType: string) {
    const queryTerms = this.getQueryTerms();
    const fqString = queryTerms.fq.length > 0 ? `&fq=${queryTerms.fq.join('%20AND%20')}` : '';
    const auth = this.configService.getConfig().apiKey ? `&key=${this.configService.getConfig().apiKey}` : '';
    let allcsvapiurl = `${this.configService.getConfig().searchApiUrl}?q=${queryTerms.q}${fqString}&type=main&start=0&rows=10000`+auth+'&format=csv';

    const urlMap = {
      allresultscsv: {url: allcsvapiurl, tag: "search_download_results", msg: "Clicks on download Results table in search"},
      modelcoordicate: {url: allcsvapiurl, tag: "search_download_coordinates", msg: "Clicks on download All model data (coordinates and PAE) in search"}
    };

    const downloadUrl = urlMap[downloadType].url;
    this.downloadService.initiateDownload(downloadUrl, downloadType, this.searchTerm, this.selectedValues());
    this.gaService.eventEmitter('search_download', 'search_results', 'click',  urlMap[downloadType].tag,  urlMap[downloadType].msg);
  }

  closeToast(): void {
    this.clearSelection();
  }

  gradientColors = [
    { percentage: 0, color: '#FF7E46' },
    { percentage: 21.35, color: '#FF7E46' },
    { percentage: 29.17, color: '#FFDC14' },
    { percentage: 52.08, color: '#FFDC14' },
    { percentage: 64.58, color: '#66CCF4' },
    { percentage: 79.61, color: '#66CCF4' },
    { percentage: 89.58, color: '#4D96D0' },
    { percentage: 100, color: '#3C70B7' }
  ];

  updateSlider() {
    if (this.minplddt > this.maxplddt) {
      this.minplddt = this.maxplddt - 1;
    } else if (this.maxplddt < this.minplddt) {
      this.maxplddt = this.minplddt + 1;
    }
  }

  getSliderBackground(): string {
    const leftPercentage = this.minplddt;
    const rightPercentage = this.maxplddt;

    let activeRange = this.getActiveGradient(leftPercentage, rightPercentage);
    return `linear-gradient(90deg, ${activeRange})`;
  }

  getActiveGradient(left: number, right: number): string {
    
    let gradient = this.gradientColors
      .filter(colorStop => colorStop.percentage >= left && colorStop.percentage <= right)
      .map(colorStop => `${colorStop.color} ${colorStop.percentage}%`)
      .join(', ');

    return `gray 0%, gray ${left}%, ${gradient}, gray ${right}%, gray 100%`;
  }

  getThumbColor(value: number): string {
    
    let leftColor = this.gradientColors[0].color;
    let rightColor = this.gradientColors[this.gradientColors.length - 1].color;

    for (let i = 0; i < this.gradientColors.length - 1; i++) {
      const start = this.gradientColors[i];
      const end = this.gradientColors[i + 1];

      if (value >= start.percentage && value <= end.percentage) {
        leftColor = start.color;
        rightColor = end.color;
        break;
      }
    }

    return rightColor; 
  }

  extractMinMax(rangeStr: string): { min: number, max: number } {
    const cleanStr = rangeStr?.replace(/[\[\]]/g, '');
  
    const parts = cleanStr?.split('+TO+');
  
    if (parts?.length === 2) {
      const min = parseFloat(parts[0]);
      const max = parseFloat(parts[1]);
      return { min, max };
    }
  
    //throw new Error("Invalid range format");
  }

  validateNumericInput(event: any) {
    const input = event.target.value;
    event.target.value = input.replace(/[^0-9]/g, ''); // Ensures only digits remain
  }

  initResultData(): void {
    this.isFilterListOpen = true;
    this.selectedFilters = {
      organismScientificName: [],
      isReviewed: [],
      isReferenceProteome: [],
      globalMetricValue: []
    };
    this.paginationData = {
      perPage: 20, currentPage: 1, totalPages: 0, pages: [], totalRecords: 0
    };
  }

  processQueryParams(path: string): void {
    //this.downloadService.updateSelectedValueSearch([]);
    if(sessionStorage[this.searchTerm]){
      const ids = JSON.parse(sessionStorage.getItem(this.searchTerm));
      this.downloadService.updateSelectedValueSearch(ids, this.searchTerm);
      //this.isAllSelectedAFDB();
    }else{
      this.downloadService.updateSelectedValueSearch([], this.searchTerm);
      this.downloadService.updateAllSelected(false);
    }
   this.isLimitReached = false;
    const urlParamsStr = path.split('?')[1];
    if (urlParamsStr) {
      const paramsList = urlParamsStr.split('&');
      for (const paramStr of paramsList) {
        const paramArr = paramStr.split('=');
        if (paramArr[0] === 'page') {
          this.paginationData.currentPage = +(paramArr[1].trim());
        } else if (paramArr[0] === 'suggested') {
          this.isSuggested = true;
        // } else if (this.filters.indexOf(paramArr[0]) > -1) {
        //   this.selectedFilters[paramArr[0]].push(decodeURIComponent(paramArr[1]).trim());
        // }
        } else {
          this.selectedFilters[paramArr[0]].push(decodeURIComponent(paramArr[1]).trim());
        }
      }
    }
  }

  transformQueryParamsToSelectedFilters(queryparams) {
    const selectedFilters = {};
    for (const [key, value] of Object.entries(queryparams)) {
      if (key  !== 'page' ) {
        this.selectedFilters[key] = [value];
      }
    }
    if(this.queryParams.globalMetricValue !== ""){
      const rangeStr = this.queryParams.globalMetricValue;
      if(rangeStr){
        const { min, max } = this.extractMinMax(rangeStr);
        this.minplddt = Number(min);
        this.maxplddt = Number(max);
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  // Function to clean/encode special characters in the URL
  fixedEncodeURIComponent(str: string): string {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  fixedEncodeURIComponentForPlddt(str: string): string {
    return encodeURIComponent(str)
      .replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      })
      .replace(/%2B/g, '+'); // Preserve the plus sign
  }

  escapeSplChars(str: string): string {
    return str.replace(/(!|"|\&\&|\|\||\{|\}|\[|\]|\^|\~|\*|\+|\-|\/|\\|\?|\(|\)|:)/g, function($1, $2) {
      return '\\' + $2;
    });
  }

  escapeSpaces(str: string): string {
    return str.replace(/\s+/g, function($1, $2) {
      return '\\' + $1;
    });
  }

  escapeSlashChars(str: string): string {
    return str.replace(/\/+/g, function($1, $2) {
      return '\\' + $2;
    });
  }

  replaceEscapedSlash(str: string): string {
    return str.replace(/\\[0-9]{2}/g, '/');
  }

  cleanParamValue(searchField: string, searchTerm: string): string {
    let cleanVal = searchTerm.trim();

    // if(/\s+/g.test(cleanVal)){
      // if containes replace space with * and remove quotes
      if(searchField !== 'globalMetricValue' ){
        cleanVal = this.escapeSplChars(cleanVal);
        cleanVal = this.escapeSpaces(cleanVal);
      }
    // }

    if(searchField === 'text' && this.isSuggested) {
      // cleanVal = `"${cleanVal}"`;
      cleanVal = `${searchField}:"${cleanVal}"`;
    }else if(searchField === 'text' && !this.isSuggested) {
      if(/\s+/g.test(cleanVal)){
        cleanVal = `${searchField}:${cleanVal}`;
      } else {
        cleanVal = `(${searchField}:*${cleanVal} OR ${searchField}:${cleanVal}*)`;
      }
    } else if(searchField === 'globalMetricValue' ){
      cleanVal = `${searchField}:${cleanVal}`;
    }
    else {
      cleanVal = `${searchField}:${cleanVal}`;
    }
   
    if(searchField === 'globalMetricValue' ){
      return this.fixedEncodeURIComponentForPlddt(cleanVal);
    }else {
      return this.fixedEncodeURIComponent(cleanVal);
    }
   
  }

  getQueryTerms(): any {
    const searchTerm = this.cleanParamValue(this.searchField, this.searchTerm);
    const queryTerms = {
      q: searchTerm,
      fq: []
    };
    for (const [key, value] of Object.entries(this.filters)) {
      if ( this.selectedFilters[value].length > 0 ) {
        this.selectedFilters[value].forEach(filterVal => {
          const fcSearchTerm = this.cleanParamValue(value, filterVal);
          queryTerms.fq.push(fcSearchTerm);
        });
      }
    }
    return queryTerms;
  }

  getAPIResults(searchApiUrl, auth, type, metaData) {
    if(metaData.searchTerm !== this.searchTerm) return;
  
    // Request API data
    this.commonService.getApiData(searchApiUrl + auth, metaData).subscribe(apiData => {
    
      if(apiData && apiData.metaData && apiData.metaData.searchTerm !== this.searchTerm) return;
      
      if(apiData.message && (apiData.message.startsWith("Job for sequence") || apiData.message.startsWith("Search in progress")))
      {
        this.loadComplete = true;
        this.isSearchprogress = true;
        this.mainResultData = null;
        this.isNoresult = false;
        this.subTimeout = setTimeout(() => {
          if(this.isSequenceSearch) this.getAPIResults(searchApiUrl, auth, type, metaData);
        }, 30000);
      }else{

        if (type === 'facets') {
          if(apiData[1] !== 404){
            this.filterData = apiData;
            const { isReviewed, isReferenceProteome } = apiData[0].facet_fields;
            // Change the order in the dict
            let isReviewedSorted = {}
            if ('true' in isReviewed) {
              isReviewedSorted['true'] = isReviewed['true'];
            }
            if ('false' in isReviewed) {
              isReviewedSorted['false'] = isReviewed['false'];
            }
            this.filterData[0].facet_fields.isReviewed = isReviewedSorted
            // Remove those are not referenced and rename the key true to isReferenced
            // if ( 'true' in isReferenceProteome ) {
            //   Object.assign(isReferenceProteome, { isReferenced: isReferenceProteome.true })['true'];
            //   delete isReferenceProteome['true']
            // }
            if ('false' in isReferenceProteome) {
                delete isReferenceProteome['false'];
            }
            this.filterData[0].facet_fields.isReferenceProteome = isReferenceProteome
          }
        } else {
        
          const urlParamsStr = this.location.path().split('?')[1];
          if (urlParamsStr) {
            const paramsList = urlParamsStr.split('&');
            for (const paramStr of paramsList) {
              const paramArr = paramStr.split('=');
              if (paramArr[0] === 'page') {
                this.paginationData.currentPage = +(paramArr[1].trim());
              } 
            }
          }
          if (apiData && apiData.numFound > 0) {
            this.isSearchprogress = false;
            this.isNoresult = false;
            if(this.searchField === "sequence"){
              this.makeSolrRequest('facets');
              const dataWithHSP = this.getHsps(apiData.docs);
              this.mainResultData = dataWithHSP;  
            }
            else {
              this.isSequenceSearch = false;
              this.mainResultData = apiData.docs;
            }
            this.paginationData.totalPages = Math.ceil(apiData.numFound / this.paginationData.perPage);
            this.paginationData.totalRecords = apiData.numFound;
            this.paginationData.pages = this.visiblePageNumbers();

            this.paginationData = Object.assign({}, this.paginationData);
            this.mainResultData = this.mainResultData.slice();
          } else {
            this.mainResultData = null;
          }
          this.selectedItems = new Array(this.mainResultData?.length).fill(false);
          this.loadComplete = true;
        }
        this.isAllSelectedAFDB();
      }
    },
    err => {
      if(this.hasGlobalMetricValue()){
        this.isRangeFilterResultsEmpty = true
      }
      this.isSequenceSearch = false;
      this.isSearchprogress = false;
      this.isNoresult = true;
      this.mainResultData = null;
      this.loadComplete = true;
    });
  }
  
  getCurrentUrlWithoutQueryParams(): string {
    const fullUrl = this.router.url; 
    const [path, queryParamsString] = fullUrl.split('?');
    
    const baseUrl = `${this.configService.getConfig().baseUrl}${path}`;
    
    if (!queryParamsString) {
        return baseUrl;
    }
    const queryParams = new URLSearchParams(queryParamsString);
    queryParams.delete('globalMetricValue');
    
    const updatedQueryParamsString = queryParams.toString();
    
    return updatedQueryParamsString ? `${baseUrl}?${updatedQueryParamsString}` : baseUrl;
  }

  makeSolrRequest(type?: string): void{
    this.isNoresult = false;
    // Show Loader
    if (type !== 'facets') { this.loadComplete = false; }

    // Set start param for pagination
    const pageStart = (this.paginationData.currentPage - 1) * this.paginationData.perPage;

    // Build queryString
    const queryTerms = this.getQueryTerms();

    const fqString = queryTerms.fq.length > 0 ? `&fq=${queryTerms.fq.join('%20AND%20')}` : '';

    // Prepare API Key
    const auth = this.configService.getConfig().apiKey ? `&key=${this.configService.getConfig().apiKey}` : '';

    // Build API url
    let searchApiUrl = `${this.configService.getConfig().searchApiUrl}?q=${queryTerms.q}${fqString}&type=main&start=${pageStart}&rows=${this.paginationData.perPage}`;

    if(this.searchField === "sequence"){
      searchApiUrl = `${this.configService.getConfig().searchApiUrl}?q=${this.searchTerm.replace(/\s+/g, '')}${fqString}&type=sequence&start=${pageStart}&rows=${this.paginationData.perPage}`;
    }
    if(this.searchField === "sequence" && type === 'facets'){
      searchApiUrl = `${this.configService.getConfig().searchApiUrl}?q=${this.searchTerm.replace(/\s+/g, '')}${fqString}&type=sequenceMultipleFacet`;
    }

    if (this.searchField !== "sequence" && type === 'facets') {
      searchApiUrl = `${this.configService.getConfig().searchApiUrl}?q=${queryTerms.q}${fqString}&type=multipleFacet`;
    }

    this.getAPIResults(searchApiUrl, auth, type, { searchTerm: this.searchTerm, isInternal: true });
  }

  getHsps(docs){
    const tempDocs = [];
    docs.forEach(doc => {
      const hsp = doc["hsps"];
      if(hsp){
        const sequence_stats = this.getSequenceStats(hsp);
        const sequenceData =  {
          accession: doc.uniprotAccession,
          query_sequence: hsp.query,
          match_sequence: hsp.match,
          match_accession: hsp.subject,
          sequence_stats,
        };
        doc['sequenceData'] = sequenceData;
      }
      tempDocs.push(doc);
    });
    return docs;
  }

  getSequenceStats = (hsp) => {
    const sequenceStats = [
        {
            label: "Identity",
            value: this.getIdentities(hsp.query, hsp.match)
        },
        {
            label: "HSP score",
            value: hsp ? hsp.score : "",
        },
        {
            label: "E-value",
            value: hsp ? hsp.expect : "",
        },
        {
            label: "Positives",
            value: this.getPositives(hsp.query, hsp.match)
        },
        {
            label: "Gaps",
            value: this.getGaps(hsp.query, hsp.subject)
        }
    ];
    return sequenceStats;
  };

  getIdentities(query,match){
    const queryLength = query !== "" ? query.replace(/-/g, '').length : 0;
    const exactMatch = this.removeSpacesAndPlusSigns(match);
    const identities = exactMatch.length;
    const percentage = Math.round((identities / queryLength) * 100) //(100 * identities) / queryLength;
    
    //return identities + "/" + queryLength + " (" + percentage + "%)"; 
    return percentage + "%";
  }

  removeSpacesAndPlusSigns(match) {
    let clearmatch = "";
    for (let i = 0; i < match.length; i++) {
      let character = match[i];
      if (character !== " " && character !== "+") {
        clearmatch += character;
      }
    }
    return clearmatch;
  }

  countDashes(hit) {
    let dashCount = 0;
    for (let i = 0; i < hit.length; i++) {
      let character = hit[i];
      if (character === "-") {
        dashCount++;
      }
    }
    return dashCount;
  }

  getPositives(query,match){
    const queryLength = query !== "" ? query.replace(/-/g, '').length : 0;
    let positiveMatch = "";
    for (let i = 0; i < match.length; i++) {
      let character = match[i];
      if (character !== " ") {
        positiveMatch += character;
      }
    }
    const positives = positiveMatch.length;
    const percentage = Math.round((positives / queryLength) * 100) //(100 * positives) / queryLength;
    return positives + "/" + queryLength+ " (" + percentage + "%)"; 
  }

  getGaps(query,hit){
    const queryLength = query !== "" ? query.replace(/-/g, '').length : 0;
    const hitGaps = this.countDashes(hit);
    const queryGaps = this.countDashes(query);
    const gaps = hitGaps + queryGaps;
    const percentage = Math.round((gaps / queryLength) * 100) //(100 * gaps) / queryLength;
    return gaps + "/" + queryLength+ " (" + percentage + "%)";
  }

  // facetAction(selectedFacet: string, facetValue: string, isChecked: boolean): void {
  //
  //   this.isFilterListOpen = false;
  //
  //   // get url params to update browser link
  //   const urlAndParamData = this.getUrlAndParams();
  //
  //   if (isChecked) {
  //     // add filter to the selected list
  //     if (this.selectedFilters[selectedFacet].indexOf(facetValue) === -1) {
  //       this.selectedFilters[selectedFacet].push(facetValue);
  //     }
  //
  //     urlAndParamData.queryParams[selectedFacet] = facetValue;
  //
  //   } else {
  //     const valIndex = this.selectedFilters[selectedFacet].indexOf(facetValue);
  //     if (this.selectedFilters[selectedFacet].indexOf(facetValue) > -1) {
  //       this.selectedFilters[selectedFacet].splice(valIndex, 1);
  //     }
  //
  //     delete urlAndParamData.queryParams[selectedFacet];
  //
  //   }
  //
  //   this.paginationData = {
  //     perPage: 20, currentPage: 1, totalPages: 0, pages: [], totalRecords: 0
  //   };
  //
  //   // push url path to maintain broweser history
  //   delete urlAndParamData.queryParams.page;
  //   this.router.navigate([urlAndParamData.locPath], { queryParams: urlAndParamData.queryParams });
  //
  //   this.makeSolrRequest('main');
  //   this.makeSolrRequest('facets');
  //
  // }

  facetActionPlddt (){
    this.isFilterListOpen = false;
    const filters = this.selectedFilters['globalMetricValue'];
   
    let isChecked = true;
    // if (filters.length !== 0) {
    //   isChecked = false;
    //   filters.length = 0; // Clears the array
    // }
    
    if (Number(this.minplddt) > Number(this.maxplddt)) {
      let temp = this.minplddt;
      this.minplddt = this.maxplddt;
      this.maxplddt = temp;
      
    }
    if (Number(this.minplddt) < 0 || Number(this.maxplddt) < 0){
      this.minplddt = Math.abs(this.minplddt);
      this.maxplddt = Math.abs(this.maxplddt);
    }

    if (Number(this.maxplddt) > 100){
      this.maxplddt = 100;
    }
    filters.push(`[${this.minplddt}+TO+${this.maxplddt}]`);
    
    this.updateBrowserLink('globalMetricValue', `[${this.minplddt}+TO+${this.maxplddt}]`, isChecked);
    this.getQueryTerms();
    this.resetPagination();
   
    if(this.searchField === "sequence"){
      this.makeSolrRequest('main');
      //this.localStorageSearchTerm = localStorage[params.term];
    }else{
      this.makeSolrRequest('main');
      this.makeSolrRequest('facets');
    }

    this.gaService.eventEmitter('search_filter_click', 'search', 'click', "search_plddt_filter", undefined)
  
  }
  facetAction(selectedFacet: string, facetValue: string, isChecked: boolean): void {
    this.isFilterListOpen = false;
    const filters = this.selectedFilters[selectedFacet];

    const toggleFilter = () => {
      if (isChecked) {
        filters.push(facetValue);
      } else {
        filters.splice(filters.indexOf(facetValue), 1);
      }
    };
    toggleFilter();

    this.updateBrowserLink(selectedFacet, facetValue, isChecked);
    this.getQueryTerms();
    this.resetPagination();
   
    if(this.searchField === "sequence"){
      this.makeSolrRequest('main');
      //this.localStorageSearchTerm = localStorage[params.term];
    }else{
      this.makeSolrRequest('main');
      this.makeSolrRequest('facets');
    }

    this.gaService.eventEmitter('search_filter_click', 'search', 'click', "search_proteome_filter", undefined)
  }

  facetActionLink(selectedFacet: string, facetValue: string): void {

    const filters = this.selectedFilters[selectedFacet];
    //this.isFilterListOpen = true;
    let isChecked = true;

    if ( filters.includes(facetValue) ) {
      filters.splice(filters.indexOf(facetValue), 1);
      isChecked = false;
    }
    else filters.push(facetValue);

    this.updateBrowserLink(selectedFacet, facetValue, isChecked);
    this.resetPagination();
    // this.makeSolrRequest('main');
    // this.makeSolrRequest('facets');

    if(this.searchField === "sequence"){
      this.makeSolrRequest('main');
      //this.localStorageSearchTerm = localStorage[params.term];
    }else{
      this.makeSolrRequest('main');
      this.makeSolrRequest('facets');
    }

    let tag = facetValue
    if ( selectedFacet === 'isReviewed') {
      tag = facetValue === 'true' ? 'search_reviewed_filter' : 'search_unreviewed_filter'
    }
    if ( selectedFacet === 'organismScientificName') {
      tag = "search_popular_filter"
    }
    this.gaService.eventEmitter('search_filter_click', 'search', 'click', tag, undefined);
  }

  isFacetActive(facetFiled: string, facetValue: string): boolean {
    const filters = this.selectedFilters[facetFiled];
    return filters.includes(facetValue)
  }

  areFiltersActives(): boolean {
    return  Object.values(this.selectedFilters).some(array => array.length > 0);
  }


  updateBrowserLink(selectedFacet: string, facetValue: string, isChecked: boolean): void {
    
    const urlAndParamData = this.getUrlAndParams();
    let params: Params = {};
    if (isChecked) {
      // urlAndParamData.queryParams[selectedFacet] = facetValue;
      params[selectedFacet] = facetValue
    } else {
      // delete urlAndParamData.queryParams[selectedFacet];
      params[selectedFacet] = null;
    }
    delete urlAndParamData.queryParams.page;
    this.router.navigate([urlAndParamData.locPath], { queryParams: params, queryParamsHandling: 'merge' });
  }

  resetPagination(): void {
    this.paginationData = {
      perPage: 20, currentPage: 1, totalPages: 0, pages: [], totalRecords: 0
    };
  }


  getUrlAndParams(): any {
    const term = this.fixedEncodeURIComponent(this.searchTerm);
    const pathData = this.location.path().split(`${term.substr(-1)}?`);
    const pathField = this.location.path().match(/\/search\/(.*)\/.*/);
    const pathUri = `/search/${pathField[1]}/`;
    const locPath = `${pathUri}${ (/\/+/g.test(this.searchTerm)) ? this.escapeSlashChars(this.searchTerm) : decodeURIComponent(this.searchTerm) }`;
    const prevParams = pathData[1];

    const urlAndParamData: any = {
      locPath,
      queryParams: {}
    };

    if (prevParams) {
      const paramsList = prevParams.split('&');
      for (const paramStr of paramsList) {
        const paramArr = paramStr.split('=');

        if (paramArr[0] === 'page') {
          urlAndParamData.queryParams['page'] = +(paramArr[1].trim());
        } else if (this.filters[paramArr[0]] > -1) {
          urlAndParamData.queryParams[paramArr[0]] = decodeURIComponent(paramArr[1]).trim();
        }

      }
    }
    return urlAndParamData;
  }

  visiblePageNumbers(): any[] {
    const innerWindow = 1;
    const outerWindow = 0;
    let windowFrom = this.paginationData.currentPage - innerWindow;
    let windowTo = this.paginationData.currentPage + innerWindow;

    // If the window is truncated on one side, make the other side longer
    if (windowTo > this.paginationData.totalPages) {
      windowFrom = Math.max(0, windowFrom - (windowTo - this.paginationData.totalPages));
      windowTo = this.paginationData.totalPages;
    }
    if (windowFrom < 1) {
      windowTo = Math.min(this.paginationData.totalPages, windowTo + (1 - windowFrom));
      windowFrom = 1;
    }

    let visible = [];

    // Always show the first page
    visible.push(1);
    // Don't add inner window pages twice
    for (let i = 2; i <= Math.min(1 + outerWindow, windowFrom - 1); i++) {
      visible.push(i);
    }
    // If the gap is just one page, close the gap
    if (1 + outerWindow == windowFrom - 2) {
      visible.push(windowFrom - 1);
    }
    // Don't add the first or last page twice
    for (var i = Math.max(2, windowFrom); i <= Math.min(windowTo, this.paginationData.totalPages - 1); i++) {
      visible.push(i);
    }
    // If the gap is just one page, close the gap
    if (this.paginationData.totalPages - outerWindow == windowTo + 2) {
      visible.push(windowTo + 1);
    }
    // Don't add inner window pages twice
    for (let i = Math.max(this.paginationData.totalPages - outerWindow, windowTo + 1); i < this.paginationData.totalPages; i++) {
      visible.push(i);
    }
    // Always show the last page, unless it's the first page
    if (this.paginationData.totalPages > 1) {
      visible.push(this.paginationData.totalPages);
    }

    var links = [];

    let prev = null;

    for (let i = 0, l = visible.length; i < l; i++) {
      if (prev && visible[i] > prev + 1) {
        links.push(-1);
      }
      links.push(visible[i]);
      prev = visible[i];
    }
    return links;
  }
  paginateTo(paginate: { source: string, pageIndex: number }): void {
    // Handle pagination logic
    if (paginate.source === 'arrow') {
      if (paginate.pageIndex === -1 && this.paginationData.currentPage === 1) return;
      if (paginate.pageIndex === 1 && this.paginationData.currentPage === this.paginationData.totalPages) return;
      this.paginationData.currentPage = this.paginationData.currentPage + paginate.pageIndex;
    } else {
      if (this.paginationData.currentPage === paginate.pageIndex) return;
      this.paginationData.currentPage = paginate.pageIndex;
    }

    // Reset select-all and initialize page selections if not already set
    this.currentPage = this.paginationData.currentPage;
    this.downloadService.updateAllSelected(false);
    this.initializePageSelections(this.currentPage);

    // Populate selectedItems based on current page’s saved selections
    this.selectedItems = [...this.pageSelections[this.currentPage]];
    this.checkSelectionLimit();

    this.paginationData.pages = this.visiblePageNumbers();
    this.paginationData = Object.assign({}, this.paginationData);
    this.makeSolrRequest('main');

    // Push URL to maintain browser history
    const urlAndParamData = this.getUrlAndParams();
    urlAndParamData.queryParams['page'] = this.paginationData.currentPage;
    this.router.navigate([urlAndParamData.locPath], { queryParams: urlAndParamData.queryParams, queryParamsHandling: 'merge' });
  }

  initializePageSelections(pageIndex: number) {
    // Initialize the selection array for this page if not yet created
    if (!this.pageSelections[pageIndex]) {
      this.pageSelections[pageIndex] = new Array(this.PAGE_SIZE).fill(false);
    }
  }

  updatePerPageVal(ppgSelected: any): void {

    // Reset to page 1
    this.paginationData.currentPage = 1;
    this.paginationData.perPage = ppgSelected.ppgValue;
    this.paginationData.pages = this.visiblePageNumbers();
    this.paginationData = Object.assign({}, this.paginationData);

    // Make solr request
    this.makeSolrRequest('main');
  }

  getResultCountText(): string{

    let title = '0 results';
    if (this.paginationData.totalRecords > 0) {
      const ppVal = this.paginationData.perPage;
      const fromVal = ((this.paginationData.currentPage - 1) * ppVal) + 1;
      let toVal = ((this.paginationData.currentPage - 1) * ppVal) + ppVal;
      if (this.paginationData.currentPage == this.paginationData.totalPages){
        toVal = this.paginationData.totalRecords;
      }
      title = `${fromVal} - ${toVal} of ${this.paginationData.totalRecords} results`;
    }
    return title;
  }

  toggleFilterList(): void {
    const filterListEle = this.el.nativeElement.querySelector('.filterList');
    filterListEle.style.height = 'auto';
    if (this.isFilterListOpen) { filterListEle.style.height = '0'; }
    this.isFilterListOpen = !this.isFilterListOpen;
  }

  ngOnDestroy(): void {
    this.locSubscriber.unsubscribe();
    this.routeSubscriber.unsubscribe();
    this.minplddt = 0;
    this.maxplddt = 100;
  }
  getDictLength(dictionary) {
    return Object.keys(dictionary).length
  }

  existField(key: string): boolean {
    return this.filterData && this.filterData[0].facet_fields && this.getDictLength(this.filterData[0].facet_fields[key]) >0
  }

  clearFilters() {
    const filters = Object.keys(this.selectedFilters);
    filters.forEach(key => this.selectedFilters[key] = []);
    const urlAndParamData = this.getUrlAndParams();
    // delete urlAndParamData.queryParams.page;
    this.resetPagination();
    // this.makeSolrRequest('main');
    // this.makeSolrRequest('facets');

    if(this.searchField === "sequence"){
      this.makeSolrRequest('main');
      //this.localStorageSearchTerm = localStorage[params.term];
    }else{
      this.makeSolrRequest('main');
      this.makeSolrRequest('facets');
    }
    this.minplddt = 0;
    this.maxplddt = 100;
    this.router.navigate([urlAndParamData.locPath]);
  }

  isReferenceOnclick(isReferenceProteome: string) {
    this.gaService.eventEmitter('search_filter_click', 'search', 'click', 'isReferenceProteome', undefined)
  }

  hasGlobalMetricValue() {
    const data = this.selectedFilters;
    return Array.isArray(data.globalMetricValue) && data.globalMetricValue.length > 0;
  }

  copySequence(sequence) {
    navigator.clipboard.writeText(sequence);
    this.isCopySequence = true;
    setTimeout(() => {this.isCopySequence = false;}, 5000);
  }

  copyLink() {
    const link = window.location.href;
    navigator.clipboard.writeText(link);
    this.isCopyLink = true;
    setTimeout(() => {this.isCopyLink = false;}, 5000);   
  }
}
function takeUntilDestroyed(destroyRef: any): import("rxjs").OperatorFunction<void, unknown> {
  throw new Error('Function not implemented.');
}

