import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  apiUrls = {};

  constructor(private http: HttpClient) {
  }
 
  /**
   * Make get API request to get the status code
   */

  getApiDatawithStatus(apiUrl: string, metaData?: any): Observable<HttpResponse<any>> {
    // Get the token & create headers
    const headerProps = {};
    if (metaData && metaData.isInternal) {
      headerProps['X-Source'] = 'alphafold';
    }
    const headers = new HttpHeaders(headerProps);
    return this.http.get(apiUrl, { headers, observe: 'response' })
      .pipe(
        tap(
          response => {
            if (metaData) {
              response.body['metaData'] = metaData;
            }
            return response;
          }
        ),
        catchError(this.handleError(apiUrl))
      );
  }

  getApiData(apiUrl: string, metaData?: any): Observable<any> {
    // Get the token & create headers
    const headerProps = {};
    if (metaData && metaData.isInternal) { headerProps['X-Source'] = 'alphafold';
    headerProps['X-Source'] = 'alphafold'; }
    const headers = new HttpHeaders(headerProps);
    return this.http.get(apiUrl, { headers })
      .pipe(
        tap(
          data => {
            if(metaData) data['metaData'] = metaData;
            return data;
          }
        ),
        catchError(this.handleError(apiUrl))
      );
  }

  postApiData(apiUrl: string, postData: any, metaData?: any): Observable<any> {
    const headerProps = {};

    if (metaData && metaData.isInternal) {
        headerProps['X-Source'] = 'test';
        // headerProps['mode'] = 'no-cors';
    }

    const headers = new HttpHeaders(headerProps);
    const body = postData;
    
    return this.http.post<any>(apiUrl, body, { headers, observe: 'response' })
        .pipe(
            map(response => {
                if (response.ok && response.body) {
                    const data = response.body;
                    if (metaData) { data['metaData'] = metaData; }
                    return { ...data, status: response.status }; // Include status in returned data
                } else {
                    throw new Error('API request failed');
                }
            }),
            catchError(error => {
                return throwError(error);
            })
        );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param apiUrl - name of the operation that failed
   */
  private handleError1(apiUrl = 'API request'): any {
    return (error: any): Observable<any> => {
      const err = [
        apiUrl,
        error.status,
        error.message
      ];
      return of(error);
    };
  }


  private handleError(apiUrl = 'API request'): any {
    return (error: HttpErrorResponse): Observable<HttpErrorResponse> => {
      const err = [
        apiUrl,
        error.status,
        // Check for specific error structures and provide messages accordingly
        error.status === 404 && error.error && Object.keys(error.error).length > 0 ? (error.error.message || "Error with additional details") : "Resource not found",
      ];

      return throwError(() => error);  // Re-throw the error for further handling if needed
    };
}

  // Comparator to retain object key/value order for template pipe
  keepDefaultOrder(a: any, b: any) {
    return a;
  }

  escapeValue(value: string): string {
    let cleanVal = value;
    // else if it is a text search, don't put quotes around the search term when there is a space
    // if (value.match(/[:\/"]/) && !value.match(/[\[\{]\S+ TO \S+[\]\}]/) && !value.match(/^["\(].*["\)]$/)) {
    //   cleanVal = '"' + value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"';
    // }
    return this.fixedEncodeURIComponent(cleanVal);
  }

  fixedEncodeURIComponent(str: string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  decodeURIValue(value: string): string {
    return decodeURIComponent(value);
  }

  blosum62Sim = [
    "AG", "AS", "DE", "DN",
    "ED", "EK", "EQ", "FL",
    "FM", "FW", "FY", "GA",
    "HN", "HQ", "HY", "IL",
    "IM", "IV", "KE", "KQ",
    "KR", "LF", "LI", "LM",
    "LV", "MF", "MI", "ML",
    "MV", "ND", "NH", "NQ",
    "NS", "QE", "QH", "QK",
    "QN", "QR", "RK", "RQ",
    "SA", "SN", "ST", "TS",
    "VI", "VL", "VM", "WF",
    "WY", "YF", "YH", "YW"
  ];

  formatAlnDiff(seq1, seq2) {
    if (seq1.length != seq2.length) return ''
    var res = ''
    for (var i = 0; i < seq1.length; i++) {
        if (seq1[i] == seq2[i]) res += seq1[i];
        else if (this.blosum62Sim.indexOf(seq1[i] + seq2[i]) != -1) res += '+';
        else res += ' ';
    }
    return res;
  }
}
