import { Injectable, signal } from '@angular/core';
import { colorGallery } from './constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  public fetchColorByIndex(index: number): string {
    return colorGallery[index % colorGallery.length];
  }

  public hexToRgb(hex): string {
    // Remove the hash symbol if present
    hex = hex.replace(/^#/, '');

    // Parse the hex color based on its length
    let bigint;
    if (hex.length === 3) {
      // Handle shorthand hex color (e.g., #abc)
      bigint = parseInt(
        hex
          .split('')
          .map((c) => c + c)
          .join(''),
        16
      );
    } else if (hex.length === 6) {
      // Handle full hex color (e.g., #aabbcc)
      bigint = parseInt(hex, 16);
    } else {
      throw new Error('Invalid hex color format');
    }

    // Extract the RGB values
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgb(${r}, ${g}, ${b})`;
  }

  private isDomainTrackClicked = new BehaviorSubject<string>(null);
  public domainTrackClicked = this.isDomainTrackClicked.asObservable();

  public clickDomainTrack(state: string): void {
    this.isDomainTrackClicked.next(state);
  }
}
