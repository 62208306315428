import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../common.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { EntryStatusComponent } from '../entry-status/entry-status.component';
import { SequenceCardComponent } from '../sequence-card/sequence-card.component';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'search-result-card',
  templateUrl: './search-result-card.component.html',
  styleUrls: ['./search-result-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultCardComponent implements OnInit {

  @Input() resultRec: any;
  //@Input() isSequenceSearch: boolean;
  @Input() resultRecIndex: number;
  kbCount: number;
  isSequenceSearch: boolean = false;
  routeSubscriber: Subscription;
  dropdownOptions: { label: string, value: any, isShow: boolean }[];

  constructor(private changeDetectorRef: ChangeDetectorRef, public commonService: CommonService, public router: Router, public gaService: GoogleAnalyticsService,
  private route: ActivatedRoute) { }

  @Input() isSelected: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isLimitReached: boolean = false;
  
  @Output() selectionChanged = new EventEmitter<{ value: string, selected: boolean }>();

  notifySelectionChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.selectionChanged.emit({ value: checkbox.value, selected: checkbox.checked });
  }

  ngOnInit(): void {
    
    //Get structure count in PDBe-KB
    const apiUrl = `https://www.ebi.ac.uk/pdbe/search/pdb/select?group=true&group.field=pdb_id&group.ngroups=true&group.facet=true&facet=true&rows=0&facet.limit=5&facet.offset=0&facet.mincount=1&facet.sort=count&omitHeader=true&json.nl=map&facet.field=uniprot_accession&facet.threads=-1&q=uniprot_accession:${this.resultRec.uniprotAccession}&wt=json`;
    this.commonService.getApiData(apiUrl).subscribe(apiData => {
      if(apiData && apiData.grouped && apiData.grouped.pdb_id) {
        this.kbCount = apiData.grouped.pdb_id.matches;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.routeSubscriber = this.route.params.subscribe(params => {
      if(params.field === "sequence"){
        this.isSequenceSearch = true;
      }
    });
   
  }

  generate_download_accessions(entry: string): string {
    return entry;
  }
  
  getPlddtLevel(plddt: number): string {
    return plddt > 90 ? 'Very High' :
           plddt > 70 ? 'High' :
           plddt > 50 ? 'Low' :
           'Very Low';
  }
  ngOnDestroy(): void {
    this.routeSubscriber.unsubscribe();
  }
}
