import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ElementRef,
  ViewChild,
  inject,
  Renderer2,
  OnInit,
  AfterViewInit,
} from '@angular/core';
@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.css'],
})
export class FileDownloadComponent implements OnChanges, AfterViewInit {
  @Input() dropdownOptions: {
    label: string;
    value: string;
    isShow: boolean;
    disabled: boolean;
    info: string;
  }[];
  @Input() amAnnotationsHg19Url: string;
  @Input() amAnnotationsHg38Url: string;
  @Input() csvAMFileUrl: string;
  @Input() isWide: boolean;
  @Input() isSvgTop: boolean;
  @Input() selectedValues: any[]; // New input for selected values

  @Output() selected = new EventEmitter<any>();

  @ViewChild('vfDropdown', { static: true }) dropdown: ElementRef;
  private readonly renderer = inject(Renderer2);

  // components = document.querySelectorAll('[data-vf-js-dropdown]');

  select(value: any) {
    this.selected.emit(value);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateDropdownOptions();
  }

  private updateDropdownOptions() {
    this.dropdownOptions = this.dropdownOptions.map((option) => {
      if (option.value === 'modelcoordicate') {
        // Set `disabled` based on whether selectedValues has entries
        return { ...option, disabled: this.selectedValues.length === 0 };
      }
      return option;
    });
  }

  ngAfterViewInit() {
    const dropdownElement = this.dropdown?.nativeElement;
    this.renderer.setAttribute(dropdownElement, 'role', 'menubar');
    this.renderer.setAttribute(dropdownElement, 'tabIndex', '0');
    this.renderer.setAttribute(dropdownElement, 'aria-expanded', 'false');

    this.renderer.listen(dropdownElement, 'click', () => {
      dropdownElement.classList.toggle('vf-dropdown--open');
    });

    // this.components = document.querySelectorAll("[data-vf-js-dropdown]");
    // const component = this.components[0];
    // component.setAttribute("role", "menubar");
    // const innerLabel = component.querySelector(".vf-dropdown__label-container");
    // innerLabel.setAttribute("tabindex", "0");
    // const links = component.querySelectorAll(".vf-dropdown__menu-container .vf-dropdown__menu-item-link");
    // const numOfLinks = links.length;
    // let currentPos = null;
    // component.setAttribute("aria-expanded", "false");

    // component.addEventListener("focusout", function() {})

    // component.addEventListener("keydown", function() {})

    // component.addEventListener("keyup", function() {})

    // component.addEventListener("click", function() {
    //   component.classList.toggle("vf-dropdown--open");
    // });
  }
}
