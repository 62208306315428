import {
  Component,
  ElementRef,
  inject,
  input,
  Input,
  OnChanges,
  signal,
  ViewChild,
} from '@angular/core';
import { TedDomain } from '../summary-text/summary-text.models';
import { UtilService } from '../util.service';
import { GoogleAnalyticsService } from '../google-analytics.service';

export interface MappedTedDomain {
  start: number;
  end: number;
  label: string;
  color: string;
  width: number;
  tedNumber: number;
  plddt: number;
  boundaries?: string;
  length?: number;
  qscore: number;
  cath_assignment_level: string;
  quality: string
}

@Component({
  selector: 'app-ted-domains',
  templateUrl: './ted-domains.component.html',
  styleUrl: './ted-domains.component.css',
})
export class TedDomainsComponent implements OnChanges {
  @Input({ required: true }) pdbeMolstar: any;
  private readonly utilService = inject(UtilService);

  public lengthOfProtein = input.required<number>();
  public tedDomainData = input.required<TedDomain[]>();
  public mappedTedDomainData = signal<MappedTedDomain[]>([]);
  public selectedIndexes = signal<number[]>([]);
  public popupVisible = signal<boolean>(false);
  public popupTooltipVisible = signal<boolean>(false);
  public activeDomain = signal<MappedTedDomain>(null);
  public activeTooltipDomain = signal<MappedTedDomain>(null);
  public tedDomainNumber = signal<number | null>(null);
  public tedDomainNumberForClickActive = signal<number | null>(null);
  public paddingLeft = signal<string>('0px');
  public paddingRight = signal<string>('0px');
  constructor(public gaService: GoogleAnalyticsService) {}
  ngOnChanges(): void {
    if (this.tedDomainData()) {
      const tedDomainData = [];
      this.tedDomainData().forEach((item, index) => {
        for (let seg of item.segments) {
          tedDomainData.push({
            start: seg.af_start,
            end: seg.af_end,
            label: item.cath_label,
            qscore: item.qscore,
            cath_assignment_level: item.cath_assignment_level,
            tedNumber: item.ted_domain_no,
            plddt: item.plddt,
            color: this.utilService.fetchColorByIndex(index),
            width: this.getResWidth(seg.af_start, seg.af_end, this.lengthOfProtein(), 322 )
          });
        }
      });
      const sortedTedDomainData = tedDomainData.sort((a, b) => {
        if (a.start === b.start) {
          return a.end - b.end;
        }
        return a.start - b.start;
      });
      const mappedTedDomainDataWithGap = sortedTedDomainData.map(
        (item, index) => {
          return {
            ...item,
            gap:
              index !== sortedTedDomainData.length - 1
                ? ((sortedTedDomainData[index + 1].start -
                    sortedTedDomainData[index].end) /
                    this.lengthOfProtein()) *
                  262
                : 0,
          };
        }
      );
      this.mappedTedDomainData.update(() => mappedTedDomainDataWithGap);
      const paddingLeft =
        ((this.mappedTedDomainData()[0].start - 1) / this.lengthOfProtein()) *
        322;
      this.paddingLeft.set(`${paddingLeft.toFixed(1)}px`);
      const paddingRight =
        ((this.lengthOfProtein() -
          this.mappedTedDomainData()[this.mappedTedDomainData().length - 1]
            .end) /
          this.lengthOfProtein()) *
        322;
      this.paddingRight.set(`${paddingRight.toFixed(1)}px`);
    }
  }

  private getResWidth(start, end, lengthOfProtein, domainWidth):number{
    const resw = (domainWidth - 8) / lengthOfProtein
    const segres = end - start + 1
    return resw * segres 
  }

  public async onHover(
    domain: MappedTedDomain,
    rectElement: HTMLElement
  ): Promise<void> {
    this.tedDomainNumber.set(domain.tedNumber);
    const similarTeds = this.mappedTedDomainData().filter(
      (item) => item.tedNumber === domain.tedNumber
    );
    let data = [];
    const boundaries = this.getBoundaries(similarTeds);
    const length = this.getTotalLength(similarTeds);
    const quality = this.getQuality(domain.qscore, domain.cath_assignment_level);
    const qscore = domain.qscore;
    const cath_assignment_level = domain.cath_assignment_level;
    similarTeds.forEach((similarDomain) => {
      data.push({
        struct_asym_id: 'A',
        start_residue_number: similarDomain.start,
        end_residue_number: similarDomain.end,
        color: similarDomain.color,
      });
    });

    await this.pdbeMolstar.visual.highlight({ data });

    this.popupTooltipVisible.set(true);
    this.activeTooltipDomain.set({ ...domain, boundaries, length, qscore, cath_assignment_level, quality });
    this.gaService.eventEmitter('domains_track', 'domains', 'hover', 'domain_hover', 'Hover on the domains track');
  }
  public onLeave(): void {
    this.pdbeMolstar.visual.clearHighlight();
    this.popupTooltipVisible.set(false);
    this.tedDomainNumber.set(null);
  }
  public async onClick(
    domain: MappedTedDomain,
    index: number,
    rectElement: HTMLElement
  ): Promise<void> {
    this.utilService.clickDomainTrack('active');
    this.tedDomainNumberForClickActive.set(domain.tedNumber);
    const similarTeds = this.mappedTedDomainData().filter(
      (item) => item.tedNumber === domain.tedNumber
    );
    const boundaries = this.getBoundaries(similarTeds);
    const length = this.getTotalLength(similarTeds);
    const quality = this.getQuality(domain.qscore, domain.cath_assignment_level);
    const qscore = domain.qscore;
    const cath_assignment_level = domain.cath_assignment_level;
    const data = [
      ...similarTeds.map((domain) => ({
        struct_asym_id: 'A',
        start_residue_number: domain.start,
        end_residue_number: domain.end,
        focus: true,
        color: domain.color,
      })),
    ];
    await this.pdbeMolstar.visual.focus(data);

    this.popupVisible.set(true);
    this.activeDomain.set({ ...domain, boundaries, length, qscore, cath_assignment_level, quality });

    if (this.popupVisible()) {
      this.popupTooltipVisible.set(false);
      this.activeTooltipDomain.set(null);
    }
    this.gaService.eventEmitter('domains_track', 'domains', 'click', 'domain_click', 'Click on the domains track');
  }

  public async closePopup(): Promise<void> {
    this.popupVisible.set(false);
    this.activeDomain.set(null);
    this.tedDomainNumberForClickActive.set(null);
    await this.pdbeMolstar.visual.reset({ camera: true });
  }

  public getQuality(qscore: number, assignment: string): string {
    if (qscore >= 75 && assignment.includes("C.A.T.H. Homologous Superfamily")) {
        return "High-confidence"; //2 stars
    } 
    if (qscore >= 70 && (assignment.includes("C.A.T.H. Homologous") || assignment.includes("C.A.T. Fold"))) {
        return "Moderate"; //1 star
    }
    return "Uncertain"; // No star/Empty star
  }

  private getBoundaries(similarTeds: MappedTedDomain[]): string {
    const mappedSimilarTeds = similarTeds.map(
      (item) => item.start + '-' + item.end
    );
    return mappedSimilarTeds.join(', ');
  }

  private getTotalLength(similarTeds: MappedTedDomain[]) {
    return similarTeds.reduce(
      (acc, current) => acc + (current.end - current.start) + 1,
      0
    );
  }
}
