export const colorGallery = [
  '#1B9E77',
  '#D95F02',
  '#7570B3',
  '#E7298A',
  '#66A61E',
  '#E6AB02',
  '#A6761D',
  '#7F3C8D',
  '#11A579',
  '#3969AC',
  '#F2B701',
  '#E73F74',
  '#80BA5A',
  '#E68310',
  '#008695',
  '#CF1C90',
  '#F97B72',
  '#66C5CC',
  '#F6CF71',
  '#F89C74',
  '#DCB0F2',
  '#87C55F',
  '#9EB9F3',
  '#FE88B1',
  '#C9DB74',
  '#8BE0A4',
  '#B497E7',
  '#E5C494',
  '#66C2A5',
  '#FC8D62',
  '#8DA0CB',
  '#E78AC3',
  '#A6D854',
  '#E41A1C',
  '#377EB8',
  '#4DAF4A',
  '#984EA3',
  '#FF7F00',
  '#FFFF33',
  '#A65628',
  '#F781BF',
];
