<div *ngFor="let faq of faqList; index as fi">
  <h3 *ngIf="!paeExplaination && !tedExplaination" class="faq-title" (click)="toggleFaq(fi)" id="{{faq.id}}">{{faq.question}}
    <i *ngIf="!openFaqs.includes(fi)" class="icon icon-common faq-toggle-icon" data-icon="&#xf078;"></i>
    <i *ngIf="openFaqs.includes(fi)" class="icon icon-common faq-toggle-icon" data-icon="&#xf077;"></i>
  </h3>

  <h3 *ngIf="paeExplaination || tedExplaination" class="paeTitle" (click)="toggleFaq(fi)" id="{{faq.id}}">{{faq.question}}
    <i *ngIf="!openFaqs.includes(fi)" class="icon icon-common faq-toggle-icon-pae" data-icon="&#xf078;"></i>
    <i *ngIf="openFaqs.includes(fi)" class="icon icon-common faq-toggle-icon-pae" data-icon="&#xf077;"></i>
  </h3>

  <p class="faq-content" [ngClass]="openFaqs.includes(fi) ? 'open-faq' :' '">
    <span class="faq-content-space">&nbsp;</span>
    <span *ngIf="!paeExplaination || !tedExplaination" [innerHTML]="faq.answer"></span>

    <span *ngIf="paeExplaination" [ngClass]="openFaqs.includes(fi) ? 'open-faq' :' '">
    <div class="faq-pae">
      
      <p>
        The Predicted Aligned Error (PAE) measures the confidence in the relative position of two residues within the predicted structure, providing insight into the reliability of relative position and orientations of different domains. Consider the human protein encoded by the gene <i>GNE</i> (<a [routerLink]="['/entry', 'Q9Y223']"  >Q9Y223</a>).
      </p>
  
      <div class="faq-pae-img mutli-image">
        <span><img src="../../assets/img/pae/Q9Y223.png" style="width: 450px;"></span>
        <span><img class="modal-small-img" src="../../assets/img/pae/Model confidence.png" style="width: 150px;"></span>
      </div>
    
      <p>
        GNE has two distinct domains according to experimentally determined structures in the Protein Data Bank (<a href="https://www.ebi.ac.uk/pdbe/pdbe-kb/proteins/Q9Y223/structures" target="_blank" >PDBe-KB</a>). Does AlphaFold confidently predict their relative positions? We can use the interactive Predicted Aligned Error (PAE) plot to answer this question.
      </p>
      <div class="faq-pae-img"><img src="../../assets/img/pae/Q9Y223 PAE.png" style="width: 300px;"></div>
    
      <p>
        The PAE plot is <b>not</b> an inter-residue distance map or a contact map. Instead, the shade of green indicates the expected distance error in Ångströms (Å), ranging from 0 Å to an arbitrary cut-off of 31 Å. The colour at (x, y) corresponds to the expected distance error in the residue x’s position when the predicted and the true structures are aligned on residue y.
        <br /> <br />
        A dark green tile corresponds to a good prediction (low error), whereas a light green tile indicates  poor prediction (high error). For example, when aligning on residue 300:  <br>
      </p>
      <ul>
        <li>We’re confident in the relative position of residue 200</li>
        <li>We’re not confident in the relative position of residue 600</li>
      </ul>
      
      <div class="faq-pae-img"><img src="../../assets/img/pae/Q9Y223 PAE-2.png" style="width: 300px;"></div>
  
      <p>
        The two low-error, dark green squares correspond to the two domains. By clicking and dragging, you can highlight these squares on the structure. If you want to remove the highlighting, click the cross icon.
      </p>
      <div class="faq-pae-img mutli-image">
        <img src="../../assets/img/pae/Q9Y223 domain.png" style="width: 367px;">
        <img src="../../assets/img/pae/Q9Y223 domain PAE.png" style="width: 321px;">
      </div>

      <p>
        When selecting an off-diagonal region, the plot visually represents the relationship between the selected ranges on the sequence and structure. The x range corresponds to the selection for scored residues, highlighted in orange, while the y range of aligned residues is highlighted in emerald green.
        <br /><br />
        The high PAE values across the whole inter-domain region indicate that for this particular protein, AlphaFold does not reliably predict the relative position of the domains.
      </p>

      <div class="faq-pae-img mutli-image">
        <img src="../../assets/img/pae/Q9Y223 domains colours.png" style="width: 367px;">
        <img src="../../assets/img/pae/Q9Y223 domains colours PAE.png" style="width: 321px;">
      </div>


      <p>Let’s consider another inter-domain example, the human protein encoded by <i>DIP2B</i> (<a href="./entry/Q9P265">Q9P265</a>).
      </p>

      <div class="faq-pae-img mutli-image">
        <img src="../../assets/img/pae/Q9P265.png" style="width: 367px;">
        <span><img class="modal-middle-img" src="../../assets/img/pae/Model confidence.png" style="width: 150px;"></span>
      </div>

      <p>In this case, we have confidence in the relative position of scored residues around 1450 when aligned with residues around 850, suggesting a packing between the small central domains.</p>

      <div class="faq-pae-img mutli-image">
        <img src="../../assets/img/pae/Q9P265 domains.png" style="width: 367px;">
        <img src="../../assets/img/pae/Q9P265 PAE.png" style="width: 321px;">
      </div>
      
      

      <p>Note that the PAE scores are asymmetrical, meaning there might be variations in PAE values between (x,y) and (y,x) positions. This is particularly relevant for loop regions with highly uncertain orientations, as seen on the DNA topoisomerase 3 (<a [routerLink]="['/entry', 'Q8T2T7']"  >Q8T2T7</a>).
      </p>

      <div class="faq-pae-img mutli-image" style="position: relative;">
        <span><img src="../../assets/img/pae/Q8T2T7.png" style="width: 400px;"></span>
        <span><img src="../../assets/img/pae/Q8T2T7 PAE.png" style="width: 334px;"></span>
      </div>

    </div>
    </span>

    <span *ngIf="tedExplaination" [ngClass]="openFaqs.includes(fi) ? 'open-faq' :' '">
      <div class="faq-pae">
        
        <p>
          <a href="https://ted.cathdb.info/about" target="_blank">The Encyclopedia of Domains (TED)</a> is a comprehensive resource that identifies and classifies protein domains within the AlphaFold Database using deep learning-based domain parsing and structure comparison. TED domains that share significant structural similarity with a previously characterised <a href="https://www.cathdb.info/wiki" target="_blank">CATH</a> domain are labelled with their predicted classification at the Topology/Fold level (3-level C.A.T. identifier) or Homologous Superfamily level (4-level C.A.T.H. identifier). Domains without classification labels indicate no similarity to CATH domains used during TED’s classification workflow.
        </p>
        <p>
          You can visualise TED Domains in the AlphaFold database entries in both the Mol* 3D Viewer and the domain annotation track displayed above the interactive PAE plot. Hovering and clicking on an individual domain track opens a tooltip with details such as the total number of residues (length), its position within the protein sequence (boundaries), the average pLDDT score, the 3-level C.A.T or 4-level C.A.T.H classification identifier and a quality metric for the domain assignment. <a [routerLink]="['/faq']" fragment="faq-26" target="_blank">Learn more in our FAQ.</a>
        </p>
        <p>Let’s explore some examples:</p>
        <p>Hemoglobin subunit gamma-1 <a href="./entry/P69891" target="_blank">(AF-P69891-F1)</a>. This protein’s PAE shows high confidence in the relative positions of almost all residues. TED identifies one domain, spanning residues 5 to 142, with a superfamily assignment.</p>
    
        <div class="faq-pae-img mutli-image">
          <span><img src="../../assets/img/ted/af-help-section-2-1st-img.png"></span>
        </div>
      
        <p>
          Note that the Domain annotation track and the Mol* 3D Viewer structure <b>colourings are mapped to individual domain boundaries and not classification levels. </b>Therefore, an entry with multiple domains of the same Fold or Homologous Superfamily will show different colours for each domain. For example, the Bacterial Ig-like domain family protein <a href="./entry/A0A009R2D9" target="_blank">(AF-A0A009R2D9-F1)</a> contains twelve domains of varying quality, mostly assigned to the same Homologous Superfamily, but each domain has a distinct colour.
        </p>
        <div class="faq-pae-img"><img src="../../assets/img/ted/af-help-section-2-2nd-img.png"></div>
      
        <p>
          Other important examples to better understand the complexity of protein architectures: <br>
        </p>
        <p>
          SPR protein <a href="./entry/A0A836E5A2" target="_blank">(AF-A0A836E5A2-F1)</a>, this entry contains one domain with insertions between segments, resulting in three boundaries, according to TED data. This illustrates how a single domain can have discontinuous segments in the sequence.<br>
        </p>
        
        <div class="faq-pae-img"><img src="../../assets/img/ted/af-help-section-2-3rd-img.png"></div>
    
        <p>
          Inosine-5'-monophosphate dehydrogenase <a href="./entry/Q21KQ8" target="_blank">(AF-Q21KQ8-F1)</a> here, where a split domain (Domain 1, green) has another domain (Domain 2, orange) nested between its segments. This demonstrates the complexity of domain architectures compared to sequence.
        </p>
        <div class="faq-pae-img"><img src="../../assets/img/ted/af-help-section-2-4th-img.png"></div>
  
        <p>
          In the help section How to interpret the Predicted Aligned Error, we discussed the multi-domain protein like Disco-interacting protein 2 homolog B <a href="./entry/Q9P265" target="_blank">(AF-Q9P265-F1)</a>. With the integration of TED data, we see that the PAE shows a high confidence in the relative positions of domain 5 and domain 3.
        </p>
  
        <div class="faq-pae-img"><img src="../../assets/img/ted/af-help-section-2-5th-imgupdated.png"></div>
  
  
        <p>In the DUF5621 domain-containing protein <a href="./entry/Q5ZSU4" target="_blank">(AF-Q5ZSU4-F1)</a>, TED identifies distinct domains, and the PAE shows an overall high confidence in the relative position of all domains.
        </p>
  
        <div class="faq-pae-img"><img src="../../assets/img/ted/af-help-section-2-6th-img.png"></div>
  
        <p>Some entries might not contain TED domains, such as the TrpR gene coding for the trp operon repressor protein <a href="./entry/A0A376RRE1" target="_blank">(AF-A0A376RRE1-F1)</a>. There are several reasons why an entry might not have any TED domains, including the structural element does not qualify as a distinct domain according to TED's criteria, the fragment might not contain a complete domain or the overall entry has a very low pLDDT.</p>
  
        <div class="faq-pae-img"><img src="../../assets/img/ted/af-help-section-2-7th-img.png"></div>
      
  
      </div>
    </span>

  <span class="faq-content-space">&nbsp;</span>
  </p>
  <hr class="faq-border"/>
</div>
