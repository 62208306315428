// TODO Add more tests

import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
  AfterViewInit,
  signal,
} from '@angular/core';
import { SummaryData } from '../summary-text/summary-text.models';
import {
  EntryData,
  SimilarStructure,
  Structure,
} from './structure-data.models';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../common.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { ConfigService } from '../config.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  applyAlphaMissenseTooltips,
  colorByAlphaMissense,
  resetColoring,
  resetTooltips,
  assignColor,
} from '../molstar-alpha-missense';

declare var PDBeMolstarPlugin: any;

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.css'],
})
export class EntryComponent implements OnInit, AfterViewInit {
  pdbeMolstar: any;
  summaryData: SummaryData;
  structure: Structure;
  accession: string;
  private sub: any;
  customEventListeners: boolean;
  entryData: EntryData;
  afdb_accession: string;
  tedDomainData: any;
  /* AlphaMissense Variables */
  csvAmData: any;
  csvAMFileUrl: string;
  amAnnotationsHg19Url: string;
  amAnnotationsHg38Url: string;
  heatmapSequence: string;
  rangeFilter: any;

  public lengthOfProtein = signal<number>(0);

  uniprotData: any;
  kbCount: number = 0;
  isSummaryLoaded = false;
  hasData: number;
  molLoadComplete: boolean = false;
  lastUpdateDate: string;
  isAlphaMis: boolean = false;
  isModalConf: boolean = true;

  isShowModelLegends: boolean = true;
  isShowModelLegendsText: boolean = false;
  private isNewsboxVisible = true; // Flag to track visibility
  private scrollListener: () => void; // Store the listener function
  isNewsSeen: string;

  viewerLegend: any = [
    { style: { backgroundColor: '#0053D6' }, label: 'Very high (pLDDT > 90)' },
    { style: { backgroundColor: '#65CBF3' }, label: 'High (90 > pLDDT > 70)' },
    { style: { backgroundColor: '#FFDB13' }, label: 'Low (70 > pLDDT > 50)' },
    { style: { backgroundColor: '#FF7D45' }, label: 'Very low (pLDDT < 50)' },
  ];

  alphamissenseLegends: any = [
    { style: { backgroundColor: '#2166ac' }, label: 'Likely benign' },
    { style: { backgroundColor: '#A8A9AC' }, label: 'Uncertain' },
    { style: { backgroundColor: '#b2182b' }, label: 'Likely pathogenic' },
  ];

  hasMMSeqData: number;
  totalMmSeq: number;
  displayedColumns: string[] = [
    'afdbAccessions',
    'uniprotDescriptions',
    'speciesNames',
  ];
  mmSeqDataSource = new MatTableDataSource<SimilarStructure>();
  errorMmSeq: string;
  isFetching: boolean = true;

  hasFoldseek: number;
  totalfoldseek: number;
  foldSeekDataSource = new MatTableDataSource<SimilarStructure>();
  @ViewChild('paginator1') paginator1!: MatPaginator;
  @ViewChild('paginator2') paginator2!: MatPaginator;
  @ViewChild('newsbox') newsbox: ElementRef<HTMLElement>;

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    public gaService: GoogleAnalyticsService,
    private configService: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef
  ) {
    this.hasData = -1;
    this.hasMMSeqData = -1;
    this.hasFoldseek = -1;
  }

  @HostListener('window:scroll')
  onScroll() {
    if (!this.isNewsSeen && this.isNewsSeen == null) {
      const heatmapEnds = document.getElementById('heatmapends');
      if (heatmapEnds) {
        const heatmapEndsTop = heatmapEnds.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;

        // Check if the heatmapEnds section is visible in the viewport
        this.isNewsboxVisible = heatmapEndsTop > viewportHeight;

        if (!this.isNewsboxVisible) {
          this.newsbox.nativeElement.classList.add('hide-from-left');
          this.removeScrollListener();
          localStorage.setItem('newsSeen', 'true');
          this.isNewsSeen = 'true';
        } else {
          this.addScrollListener(); // Add listener if needed (optional)
        }
      }
    }
  }

  async awaitElementIsRendered(querySelectorTxt) {
    return await new Promise((resolve) => {
      if (document.querySelector(querySelectorTxt)) {
        return resolve(document.querySelector(querySelectorTxt));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(querySelectorTxt)) {
          observer.disconnect();
          resolve(document.querySelector(querySelectorTxt));
        }
      });
      // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  removeScrollListener() {
    if (this.scrollListener) {
      window.removeEventListener('scroll', this.scrollListener);
      this.scrollListener = null; // Clear the reference
    }
  }

  addScrollListener() {
    // Optional, add listener if needed after initial check
    if (!this.scrollListener) {
      this.scrollListener = this.onScroll.bind(this); // Ensure proper context
      window.addEventListener('scroll', this.scrollListener);
    }
  }

  handlePageEvent(e) {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.isNewsSeen = localStorage.getItem('newsSeen');
    this.sub = this.route.params.subscribe((params) => {
      // Set the key for all the API endpoint, i.e. the UniProt accession
      this.accession = params.id;
      // Allow the use of both URL patterns /entry/[UNP_ACCESSION] and /entry/AF-[UNP_ACCESSION]
      if (this.accession.indexOf('AF-') > -1) {
        this.accession = this.accession.substring(3);
      }
      // Emit event for Google Analytics
      this.gaService.eventEmitter(
        'go_to_entry_page',
        'entry_page',
        'visit',
        this.accession,
        undefined
      );
      this.getEntryData();
    });
  }

  ngAfterViewInit() {
    this.getDomainData();
  }

  stopChangePropagation(event: Event, type) {
    if (type === 'missense')
      this.gaService.eventEmitter(
        'AM_link_visit',
        'AlphaMissense',
        'click',
        'AM_tooltip_link_paper',
        'Clicks on the link paper for AM'
      );
    event.stopPropagation();
  }

  newsShowLinkClick(event: Event) {
    const percentnum = window.innerWidth === 1920 ? 150 : 230;
    const targetPosition = window.innerHeight * (percentnum / 100);
    window.scroll({ top: targetPosition, behavior: 'smooth' });
    localStorage.setItem('newsSeen', 'true');
    this.isNewsSeen = 'true';
    this.newsbox.nativeElement.classList.add('hide-from-left');
  }

  toggleShowModel() {
    let event_name = 'Colour_legend_hidden';
    let event_lable = 'hide_colour_legend_click';
    if (!this.isShowModelLegends) {
      event_name = 'Colour_legend_shown';
      event_lable = 'show_colour_legend_click';
    }
    this.gaService.eventEmitter(
      event_name,
      '3D_colour_legend',
      'click',
      event_lable,
      'Clicks on Show/Hide colour legend link button'
    );
    this.isShowModelLegends = !this.isShowModelLegends;
    this.isShowModelLegendsText = !this.isShowModelLegendsText;
  }

  async getAmData() {
    if (this.csvAMFileUrl) {
      const fileurl = this.csvAMFileUrl;
      // const fileurl =
      //   'http://localhost:4200/assets/new-AF-A0A0A0MRZ7-F1-aa-substitutions.csv';
      const fileresponse = await fetch(fileurl);
      const csvData2 = await fileresponse.text();
      this.csvAmData = csvData2;
      this.changeDetectorRef.detectChanges();
    }
  }

  public getDomainData(): void {
    const auth = this.configService.getConfig().apiKey
      ? `?key=${this.configService.getConfig().apiKey}`
      : '';
    this.commonService
      .getApiData(
        this.configService.getConfig().domainsApiUrl + this.accession + auth,
        { isInternal: true }
      )
      .subscribe(
        (data) => {
          this.tedDomainData = data.annotations;
        },
        (error) => {
          this.tedDomainData = [];
        }
      );
  }

  getEntryData(): void {
    const auth = this.configService.getConfig().apiKey
      ? `?key=${this.configService.getConfig().apiKey}`
      : '';
    this.commonService
      .getApiData(
        this.configService.getConfig().entryApiUrl + this.accession + auth,
        { isInternal: true }
      )
      .subscribe((data) => {
        if (data?.length > 1 && data[1] !== 200) {
          this.hasData = 0;
          this.removeMolstarBox();
          return;
        } else {
          this.hasData = 1;
          this.entryData = data[0];
          this.isSummaryLoaded = true;
          this.heatmapSequence = data[0].uniprotSequence
            ? data[0].uniprotSequence
            : undefined;
          this.lengthOfProtein.set(data[0].uniprotEnd);
          this.amAnnotationsHg19Url = data[0].amAnnotationsHg19Url
            ? data[0].amAnnotationsHg19Url
            : undefined;
          this.amAnnotationsHg38Url = data[0].amAnnotationsHg38Url
            ? data[0].amAnnotationsHg38Url
            : undefined;
          if (data[0].entryId) {
            this.afdb_accession = `${data[0].entryId}`;
          }

          if (data[0].amAnnotationsUrl) {
            this.csvAMFileUrl = data[0].amAnnotationsUrl;
            this.getAmData();
          } else {
            this.csvAMFileUrl = undefined;
            this.csvAmData = undefined;
          }
          this.structure = {
            model_url: this.entryData.cifUrl,
            model_format: 'cif',
            binary: false,
          };

          // Set last updated date
          const modelCreatedDate = new Date(this.entryData.modelCreatedDate);
          this.lastUpdateDate = `${modelCreatedDate.getDate()} ${modelCreatedDate.toLocaleString(
            'default',
            { month: 'long' }
          )} ${modelCreatedDate.getFullYear()}`;
          setTimeout(() => {
            this.handleMolstar(this.structure);
          }, 10);
        }
      });
  }

  getClusterFoldseekData() {
    const auth = this.configService.getConfig().apiKey
      ? `&key=${this.configService.getConfig().apiKey}`
      : '';
    let apiUrl = '';
    const configUrl = this.configService.getConfig().clusterApiUrl
      ? this.configService.getConfig().clusterApiUrl
      : 'https://test.alphafold.ebi.ac.uk/api/cluster/members/';
    apiUrl = configUrl + this.accession + '?cluster_flag=AFDB%2FFoldseek';

    this.commonService
      .getApiData(apiUrl + auth, { isInternal: true })
      .subscribe(
        (data) => {
          if (data.clusterTotal > 0) {
            this.hasFoldseek = 1;
            this.totalfoldseek = data.clusterTotal;
            const tempFoldseekData = data.clusterMembers
              ? data.clusterMembers
              : {};
            const similarStructureFoldSeek =
              this.transformClusterData(tempFoldseekData);
            this.foldSeekDataSource = new MatTableDataSource<SimilarStructure>(
              similarStructureFoldSeek
            );
            this.foldSeekDataSource.paginator = this.paginator2;
          } else {
            this.hasFoldseek = -1;
            this.totalfoldseek = 0;
            this.foldSeekDataSource = null;
            this.errorMmSeq = 'This structure has no cluster members.';
          }
        },
        (err) => {
          this.hasFoldseek = -1;
          this.totalfoldseek = 0;
          this.foldSeekDataSource = null;
          this.errorMmSeq = 'This structure has no cluster members.';
        }
      );
  }

  getClusterMmSeqData() {
    const auth = this.configService.getConfig().apiKey
      ? `&key=${this.configService.getConfig().apiKey}`
      : '';

    let apiUrl = '';
    const configUrl = this.configService.getConfig().clusterApiUrl
      ? this.configService.getConfig().clusterApiUrl
      : 'https://test.alphafold.ebi.ac.uk/api/cluster/members/';
    apiUrl = configUrl + this.accession + '?cluster_flag=AFDB50%2FMMseqs2';

    this.commonService
      .getApiData(apiUrl + auth, { isInternal: true })
      .subscribe(
        (data) => {
          if (data.clusterTotal > 0) {
            this.hasMMSeqData = 1;
            this.totalMmSeq = data.clusterTotal;
            const tempMmSeqData = data.clusterMembers
              ? data.clusterMembers
              : {};
            const similarStructure = this.transformClusterData(tempMmSeqData);
            this.mmSeqDataSource = new MatTableDataSource<SimilarStructure>(
              similarStructure
            );
            this.mmSeqDataSource.paginator = this.paginator1;
          } else {
            this.hasMMSeqData = -1;
            this.totalMmSeq = 0;
            this.mmSeqDataSource = null;
            this.errorMmSeq = 'This structure has no cluster members.';
          }
        },
        (err) => {
          this.hasMMSeqData = -1;
          this.totalMmSeq = 0;
          this.mmSeqDataSource = null;
          this.errorMmSeq = 'This structure has no cluster members.';
        }
      );
  }

  transformClusterData(data) {
    const similarClusterData = [];
    if (data) {
      for (let i = 0; i < data['afdbAccessions'].length; i++) {
        similarClusterData.push({
          afdbAccessions: data['afdbAccessions'][i],
          uniprotDescriptions: data['uniprotDescriptions'][i],
          speciesNames: data['speciesNames'][i],
        });
      }
    }
    return similarClusterData;
  }

  handleMolstar(structure: Structure): void {
    const options = {
      customData: {
        // url: structure.model_url,
        url: `https://alphafold.ebi.ac.uk/files/AF-${this.accession}-F1-model_v4.cif`,
        format: structure.model_format,
        binary: structure.binary,
      },
      subscribeEvents: false,
      bgColor: { r: 255, g: 255, b: 255 },
      selectInteraction: false,
      alphafoldView: true,
      reactive: true,
      sequencePanel: true,
      hideCanvasControls: ['animation'],
      landscape: true,
    };

    if (this.pdbeMolstar) {
      this.pdbeMolstar.visual.update(options, true);
    } else {
      const ele = this.el.nativeElement.querySelector('.molstar-container');
      if (ele) {
        this.pdbeMolstar = new PDBeMolstarPlugin();
        this.pdbeMolstar.render(ele, options);

        // Update Mol load complete flag
        this.pdbeMolstar.events.loadComplete.subscribe((e) => {
          this.molLoadComplete = true;
        });
      }
    }
  }

  applyMolstarColoring(kind: 'plddt' | 'missense') {
    if (kind === 'missense') {
      this.isAlphaMis = true;
      this.isModalConf = false;
      // colorByAlphaMissense(this.pdbeMolstar, this.csvAmData);
      if (this.rangeFilter) {
        colorByAlphaMissense(
          this.pdbeMolstar,
          this.csvAmData,
          this.rangeFilter
        );
      } else {
        colorByAlphaMissense(this.pdbeMolstar, this.csvAmData);
      }
      applyAlphaMissenseTooltips(this.pdbeMolstar, this.csvAmData);
      this.gaService.eventEmitter(
        'AM_toggle',
        'AlphaMissense',
        'click',
        'AM_toggle',
        'Toggle between AM and pLDDT colouring on Mol*'
      );
    } else if (kind === 'plddt') {
      this.isAlphaMis = false;
      this.isModalConf = true;
      resetColoring(this.pdbeMolstar);
      resetTooltips(this.pdbeMolstar);
    } else {
      console.error('Unknow coloring kind:', kind);
    }
  }

  clearSeqSelection(): void {
    const selectedSeqElements = this.el.nativeElement.querySelectorAll(
      '.ms-pae-selection, .ms-pae-select-green, .ms-pae-select-orange'
    );
    if (selectedSeqElements) {
      selectedSeqElements.forEach((selEle) => {
        selEle.classList.remove('ms-pae-selection');
        selEle.classList.remove('ms-pae-select-green');
        selEle.classList.remove('ms-pae-select-orange');
      });
    }
  }

  applySeqSelection(selectionData) {
    this.clearSeqSelection();
    selectionData.forEach((ele) => {
      for (
        let seqid = ele.start_residue_number;
        seqid <= ele.end_residue_number;
        seqid++
      ) {
        const seqElement = this.el.nativeElement.querySelector(
          `.msp-sequence-present[data-seqid="${seqid}"`
        );
        if (seqElement) {
          seqElement.classList.add(`${ele.class}`);
        }
      }
    });
  }

  validateBlankSelection(regions) {
    regions.forEach((select) => {
      if (select.start === 0 && select.end === 0) {
        this.clearSeqSelection();
        this.pdbeMolstar.visual.reset({ camera: true });
      }
    });
    return;
  }

  getSelectionData(regions) {
    let selection = [];
    if (
      regions[0].start < regions[1].start &&
      regions[0].end > regions[1].end
    ) {
      selection.push({
        start_residue_number: regions[0].start,
        end_residue_number: regions[1].end,
        color: '#1AFFBB',
        focus: true,
        class: 'ms-pae-select-green',
      });
    } else if (
      Math.abs(regions[0].start - regions[1].start) <= 5 ||
      Math.abs(regions[0].end - regions[1].end) <= 5
    ) {
      selection.push({
        start_residue_number: Math.min(regions[0].start, regions[1].start),
        end_residue_number: Math.max(regions[0].end, regions[1].end),
        color: '#1AFFBB',
        focus: true,
        class: 'ms-pae-select-green',
      });
    } else {
      selection.push(
        {
          start_residue_number: regions[0].start,
          end_residue_number: regions[0].end,
          color: '#FFA500',
          focus: true,
          class: 'ms-pae-select-orange',
        },
        {
          start_residue_number: regions[1].start,
          end_residue_number: regions[1].end,
          color: '#1AFFBB',
          focus: true,
          class: 'ms-pae-select-green',
        }
      );
    }
    return selection;
  }

  validateRegions = (regions) => {
    let valid = true;
    for (const region of regions) {
      if (region.start === 0 && region.end === 0) {
        valid = false;
        return;
      }
    }
    return valid;
  };

  async handleCmUpdate(regions): Promise<void> {
    if (!this.molLoadComplete) {
      return;
    }

    const validRegions = this.validateRegions(regions);

    if (!validRegions) {
      this.clearSeqSelection();
      await this.applyMolstarColoring(this.isAlphaMis ? 'missense' : 'plddt');

      this.pdbeMolstar.visual.reset({ camera: true });
      return;
    }

    let adjustedRegions = [];
    for (const region of regions) {
      if (region.start === 0) {
        region.start = 1;
      }
      if (region.end === 0) {
        region.end = 1;
      }
      adjustedRegions.push(region);
    }

    const selectionData = this.getSelectionData(adjustedRegions);

    this.pdbeMolstar.visual.select({
      data: selectionData,
      nonSelectedColor: '#707372',
      keepRepresentations: true,
    });

    this.applySeqSelection(selectionData);
  }

  async handleAmUpdate(data): Promise<void> {
    if (!this.molLoadComplete) {
      return;
    }

    const color = assignColor(data.score);
    await this.pdbeMolstar.visual.select({
      data: [
        {
          residue_number: data.dataX,
          color: null,
          representation: 'ball-and-stick',
          representationColor: color,
          focus: true,
        },
      ],
      keepColors: true,
    });

    await this.pdbeMolstar.visual.highlight({
      data: [{ residue_number: data.dataX }],
      // nonSelectedColor: '#000000'
    });
  }

  removeMolstarBox(): void {
    const ele = document.getElementById('data-visualisation-box');
    ele.innerHTML = '';
  }

  toggleUpdateFlags(data) {
    this.isAlphaMis = data.isAlphaMis;
    this.isModalConf = data.isModalConf;
  }

  cmSelectionUpdate(cmSelectedRegions): void {
    this.handleCmUpdate(cmSelectedRegions);
  }

  amSelectionUpdate(data): void {
    if (data.dataX > 0) {
      this.handleAmUpdate(data);
    } else {
      this.pdbeMolstar.visual.reset({ camera: true });
    }
  }

  amSelectionCategory(data): void {
    if (this.isAlphaMis) {
      this.rangeFilter = data.range;
      this.applyMolstarColoring('missense');
    }
  }

  amSelectionClear() {
    this.pdbeMolstar.visual.clearSelection(undefined, { keepColors: true });
    this.pdbeMolstar.visual.reset({ camera: true });
  }

  ngOnDestroy() {
    // Remove listener when component is destroyed
    this.removeScrollListener();
  }
}
