<div *ngIf="hasData == -1" class="row" id="loading">
  <div class="small-12 columns" style="text-align: center; margin: 90px 0">
    <img src="../../assets/img/loader.gif" />
  </div>
</div>

<div *ngIf="hasData == 0" class="row" id="not-found">
  <div class="small-11 small-offset-1 columns">
    <h1>Error: 404</h1>
    <h2>We’re sorry - we can’t find the page or file you requested.</h2>
    <p>
      We’re sorry - we can’t find the page or file you requested.<br />
      It may have been removed, had its name changed, or be temporarily
      unavailable.<br />
      You might try searching for it or go <a href="/">back to homepage</a>.
    </p>
  </div>
</div>

<div *ngIf="hasData == 1" #newsbox class="news-box">
  <app-news-popup (showLinkClick)="newsShowLinkClick($event)"></app-news-popup>
</div>

<div class="row entrySummary" *ngIf="hasData == 1">
  <div class="columns small-12">
    <app-summary-text
      [accession]="accession"
      [data]="summaryData"
      [csvAmData]="csvAmData"
      [csvAMFileUrl]="csvAMFileUrl"
      [entryData]="entryData"
      (click)="
        gaService.eventEmitter(
          'summary',
          'summary',
          'click',
          accession,
          undefined
        )
      "
    ></app-summary-text>
  </div>
</div>

<div class="row threedViewer" *ngIf="hasData == 1">
  <div class="columns structureTitleBox">
    <div class="filledBox structurestart">
      <span class="title" id="structurestart">Structure viewer</span>
    </div>
  </div>
</div>

<div class="row" *ngIf="hasData == 1">
  <div class="left-viewer">
    <p class="title title-header" id="structureend">Mol* 3D Viewer</p>
    <app-structure-viewer
      (selectedToggle)="toggleUpdateFlags($event)"
      [isModalConf]="isModalConf"
      [isAlphaMis]="isAlphaMis"
      [accession]="accession"
      [csvAmData]="csvAmData"
      [isShowModelLegendsText]="isShowModelLegendsText"
      [isShowModelLegends]="isShowModelLegends"
      [pdbeMolstar]="pdbeMolstar"
      [tedDomainData]="tedDomainData"
    >
    </app-structure-viewer>
  </div>
  <div class="right-viewer">
    <p class="title title-header" id="structureend">
      TED Domains and Predicted Aligned Error (PAE)
    </p>
    <app-ted-domains
      [tedDomainData]="tedDomainData"
      [lengthOfProtein]="lengthOfProtein()"
      [pdbeMolstar]="pdbeMolstar"
    ></app-ted-domains>
    <app-pae-view
      *ngIf="isSummaryLoaded && entryData.paeImageUrl"
      [accession]="accession"
      [imageUrl]="entryData.paeImageUrl"
      [length]="entryData.uniprotEnd"
      (selectedRegion)="cmSelectionUpdate($event)"
      (click)="
        gaService.eventEmitter(
          'pae-click',
          'pae',
          'click',
          accession,
          undefined
        )
      "
    >
    </app-pae-view>
  </div>
</div>

<div id="heatmapends"></div>
<app-heatmap
  [accession]="accession"
  [amAnnotationsHg19Url]="amAnnotationsHg19Url"
  [amAnnotationsHg38Url]="amAnnotationsHg38Url"
  [csvAMFileUrl]="csvAMFileUrl"
  [heatmapSequence]="heatmapSequence"
  [csvAmData]="csvAmData"
  (selectedCategory)="amSelectionCategory($event)"
  (selectedRegion)="amSelectionUpdate($event)"
  (clearRegion)="amSelectionClear()"
  *ngIf="csvAmData"
></app-heatmap>
<app-foldseek-table
  [afdb_accession]="afdb_accession"
  [accession]="accession"
  *ngIf="hasData == 1"
></app-foldseek-table>

<app-cluster-table
  [accession]="accession"
  *ngIf="hasData == 1"
></app-cluster-table>

<app-entry-help [accession]="accession" *ngIf="hasData == 1"></app-entry-help>
